import "./App.css";
import Home from "./pages/Home";
import Nav from "./pages/Nav";
import Logo from './img/main-logo.png';
import React, { FC, useMemo, useState, useEffect } from "react";
import TraitBG from './img/trait-bg.png';
import CustomizeBG from "./img/customizer-bg.png"
import MintBG from "./img/mint-background.png"
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { UnsafeBurnerWalletAdapter } from '@solana/wallet-adapter-wallets';
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { WalletWrapper } from "./components/UI/StyledComponents";
import {
  GlowWalletAdapter,
  LedgerWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

import { clusterApiUrl } from "@solana/web3.js";
import { ThemeProvider } from "styled-components";

require("@solana/wallet-adapter-react-ui/styles.css");

function App() {
  // console.log("this is the node version", process.version)
  const network = process.env.REACT_APP_SOLANA_NETWORK;
  // console.log(network)
  const endpoint = process.env.QUICKNODE;
  // console.log(endpoint)
  // useMemo(() => clusterApiUrl(network), [network]);
  const [background, setBackground] = useState("homepage");
  const pageHeight = window.innerHeight;
  const currentPage = window.location.href;
  const path = window.location.pathname.replace("/", "");

  const [projectDict, setProjectDict] = useState();

  const [projectID, setProjectID] = useState();
  const [projectLink, setProjectLink] = useState();
  const [projectHash, setProjectHash] = useState();
  const [projectName, setProjectName] = useState();
  const [projectCreator, setProjectCreator] = useState();
  const [projectLogo, setProjectLogo] = useState();
  const [isLoading, setIsLoading] = useState(true);

  let initalPage = 'astralTraits'
  const [page, setPage] = useState(initalPage);

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    // console.log(windowDimensions, aspectRatio)
  }, [windowDimensions])

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const aspectRatio = windowDimensions.width / windowDimensions.height;


  useEffect(() => {
    var data = JSON.stringify({
      pathName: 'astrals',
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/getproject",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.isClient) {
          setProjectDict({
            projectID: response.data.projectID,
            projectLink: response.data.projectLink,
            projectHash: response.data.projectHash,
            projectName: response.data.projectName,
            projectCreator: response.data.projectCreator,
            projectLogo: response.data.logoURL,
            projectColors: response.data?.projectColors,
            projectTraitCollectionHash: response.data.projectTraitCollectionHash,
            swapTraits: response.data.swapTraits,
            fusions: response.data.fusions,
            mutations: response.data.mutations,
            slotMachine: response.data.slotMachine,
            hasReferral: response.data.hasReferral,
            referralWallet: response.data.referralWallet,
            referralPercentage: response.data.referralPercentage,
            hasFusionFee: response.data.hasFusionFee,
            fusionFeeCurrency : response.data.fusionFeeCurrency,
            fusionFeePrice : response.data.fusionFeePrice,
            underMaintenance: response.data.underMaintenance
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const wallets = useMemo(
    () => [
      new UnsafeBurnerWalletAdapter(),
      new SolflareWalletAdapter({ network }),
    ],
    [network]
  );

  return (
    <div
      className="bg-local bg-cover bg-no-repeat h-screen overflow-y-auto overflow-x-auto"
      style={{
        backgroundImage: `url(${page === 'customAstral' ? CustomizeBG: TraitBG})`,
        backgroundPosition: 'center bottom'
      }}
    >
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            {isLoading ? (
              ""
            ) : (
              <>
                {projectDict ?
                  <>
                  {
                    projectDict.underMaintenance ?
                    <section className='bg-white-off w-full h-full py-10 md:py-32'>
                      <div className="bg-white-off py-10 md:py-32">
                          <div className='max-w-[550px] mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-x-12 px-5 mb-5'>
                            <div className="logo">
                                <img src={Logo} alt="Logo" />
                            </div>
                            <div className="logo">
                              <h1 className='font-title-bold text-4xl font-bold text-primary-red'>THE</h1>
                              <h1 className='font-title-bold text-4xl font-bold text-primary-red'>BODY SHOP</h1>
                              <p className='font-text font-medium'>
                                a robust trait marketplace
                                to upgrade and customize
                                your NFTs powered by maxin'
                              </p>
                            </div>
                          </div>
                          <h1 className='font-title-bold text-4xl font-bold text-primary-red text-center'>UNDER MAINTENANCE</h1>
                      </div>

                    </section>
                    :
                    <ThemeProvider theme={projectDict?.projectColors}>
                        <Nav
                          projectName={projectDict.projectName}
                          projectHashURL={projectDict.projectHash}
                          projectLink={projectDict.projectLink}
                          projectID={projectDict.projectID}
                          projectCreator={projectDict.projectCreator}
                          projectLogo={projectDict.projectLogo}
                          projectTraitCollectionHash={projectDict.projectTraitCollectionHash}
                          swapTraits={projectDict.swapTraits}
                          fusions = {projectDict.fusions}
                          mutations = {projectDict.mutations}
                          slotMachine = {projectDict.slotMachine}
                          hasReferral = {projectDict.hasReferral}
                          referralWallet = {projectDict.referralWallet}
                          referralPercentage = {projectDict.referralPercentage}
                          hasFusionFee = {projectDict.hasFusionFee}
                          fusionFeeCurrency = {projectDict.fusionFeeCurrency}
                          fusionFeePrice = {projectDict.fusionFeePrice}
                          setPage = {setPage}
                        >
                          <div className="">
                            <WalletWrapper>
                              <WalletMultiButton
                                style={{ color: "white"}}
                                className="customButton"
                              />
                            </WalletWrapper>
                          </div>
                        </Nav>
                    </ThemeProvider>
                  }
                  </>
                  :
                  ""
                }
              </>
            )}
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </div>
  );
}

export default App;

// <div
//   className="bg-local bg-cover bg-center bg-no-repeat overflow-auto"
//   style={{
//     backgroundImage: `url(${page === 'astralTraits' ? TraitBG : CustomizeBG})`,
//     minHeight: '100vh',
//   }}
// >
