import SideBarNew from "../components/SideBarNew";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import React, { useCallback, useState, useEffect } from "react";
import { getParsedNftAccountsByOwner } from "@nfteyez/sol-rayz";
import * as web3 from "@solana/web3.js";
import axios from "axios";
import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, getMinimumBalanceForRentExemptMint, getAssociatedTokenAddress, createAssociatedTokenAccountInstruction, getAccount, createTransferInstruction} from '@solana/spl-token';
import { Connection, Keypair, PublicKey, SystemProgram, Transaction, LAMPORTS_PER_SOL } from "@solana/web3.js";
import * as bs58 from "bs58";
import Loader from "../img/loading.gif";
import Failed from "../img/failedtransaction.png";
import Backdrop from "../components/UI/Backdrop";
import Reload from '../img/reload.png'
import { P, SelectNFTBtn, UpgradeNowBtn } from "../components/UI/StyledComponents";
import { useTheme } from "styled-components";
import { uploadFile } from 'react-s3';
import S3 from 'react-aws-s3';
import MintBtnOn from '../img/mint-btn-on.png'
import MintBtnOff from '../img/mint-btn-off.png'
import BackTraitsOn from '../img/back-to-traits-on.png'
import BackTraitsOff from '../img/back-to-traits-off.png'
import PopupMint from '../img/popup-mint.png'
import MintBG from "../img/mint-background.png"

import {
  findMetadataPda,
  findMasterEditionV2Pda,
  Metaplex,
  keypairIdentity,
  walletAdapterIdentity
} from '@metaplex-foundation/js';

const AstralsMint = (props) => {
  // // // console.log(props.setPage)
  const [page, setPage] = useState();
  const theme = useTheme();
  const { connection } = useConnection();
  const wallet = useWallet();
  const { publicKey, sendTransaction,signTransaction } = useWallet();

  const [walletNFTs, setWalletNFTs] = useState([])
  const [ownedNFTDict, setOwnedNFTDict] = useState({})

  const [popupState, setPopupState] = useState("default");

  // booleans
  const [fetchedWalletNFTs, setFetchedWalletNFTs] = useState(false);
  const [filteredProjectNFTs, setFilteredProjectNFTs] = useState(false);
  const [loadNFTs, setLoadNFTs] = useState(false)
  const [popup, setPopup] = useState(false);

  // project variables
  const [userProjectNFTs, setUserProjectNFTs] = useState({})
  const projectHashURL = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/hashes/52/hash_list_B9REbEXGse3JD2EtypAt2rDwPniA57AtPMCQ8n4WfYnK.json"
  const [projectHashArray, setProjectHashArray] = useState([])
  const [metadataRequirements, setMetadataRequirements] = useState(['Tail'])
  const [currentlyUpgrading, setCurrentlyUpgrading] = useState({})

  //user variables
  const [sortedData, setSortedData] = useState()
  const [selectedNFT, setSelectedNFT] = useState()
  const [imageArray, setImageArray] = useState([]);
  const [readyToUpgrade, setReadyToUpgrade] = useState(false)
  const [metadata, setMetadata] = useState({})
  const [newMetadataID, setNewMetadataID] = useState({})
  const [metadataLink, setMetadataLink] = useState();

  const [ableToMint, setAbleToMint] = useState(false)
  const [buttonClicked, setButtonClicked] = useState(false)

  const [fetchedMintStatus, setFetchedMintStatus] = useState(false)
  const [pendingNFTs, setPendingNFTs] = useState([])

  useEffect(() => {
    if (publicKey && !fetchedMintStatus){
      var data = JSON.stringify({
        action: 'getPendingMints',
        userWallet: publicKey.toBase58()
      });

      var config = {
        method: "post",
        url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/astralmint",
        headers: {
          "x-api-key": process.env.GATEWAY_KEY,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
      .then(function (response) {
        // console.log(response.data)
        setPendingNFTs(response.data.pendingNFTs)
        setFetchedMintStatus(true)
      })
      .catch(function (error) {
        // // // console.log(error);
      });
    }
  }, [publicKey, fetchedMintStatus])

  useEffect(() => {
    if (publicKey && !fetchedWalletNFTs){
      const getNFTs = async () => {
        let myNfts = await getParsedNftAccountsByOwner({
          publicAddress: publicKey.toBase58(),
          connection: connection,
          serialization: true,
        });
        let walletDictTemp = {}
        myNfts.forEach((nft) => {
          walletDictTemp[nft.mint] = nft.data.uri
        });
        setFetchedWalletNFTs(true)
        setWalletNFTs(myNfts)
      };
      getNFTs();
    }
  }, [publicKey, fetchedWalletNFTs])

  // well use this to determine the minting eligibility
  useEffect(() => {
    if (buttonClicked){
      setAbleToMint(true)
    }
  }, [buttonClicked]);

  const setUpgradeNFT = (selectedNFTDict) => {
    setSelectedNFT(selectedNFTDict.hash)
    var data = JSON.stringify({
      projectID: props.projectID,
      selectedNFT: selectedNFTDict,
      mutationType: 'Tails',
      action: "RRTailsMutation"
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/mutations",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
    .then(function (response) {
      // console.log(response.data)
      if (!response.data.error && !("errorMessage" in response.data)){
        setReadyToUpgrade(true)
        setMetadataLink(selectedNFTDict.metadataLink)
        setMetadata(response.data.newMetadata)
        setNewMetadataID(response.data.newMetadataID)
        setImageArray(response.data.imageArray)
        setSortedData(response.data.finalSortedData)
      } else {
        setPopup(true);
        setPopupState("unexpectedError");
      }
    })
    .catch(function (error) {
      // // // console.log(error);
    });
  }

  const randomHash = (length) => {
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let str = "";
    for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
  };

  const selectRandomRace = () => {
    let races = [
      "Akabo",
      "Akabo Skeez",
      "Azuli Citizen",
      "Azuli Cardinal",
      "Belua",
      "Belua Rogue",
      "Boraqchk",
      "Boraqchk Explorer",
      "Broos",
      "Broos Rage",
      "Contemplar Mechanic",
      "Contemplar Foreman",
      "Gulatroxian",
      "Gulatroxian Mutant",
      "Peaky Inruina",
      "Inruina Grunt",
      "Nihivor Drone",
      "Nihivor Noble",
      "Oevrumetus",
      "Tribal Oevrumetus",
      "Regina Hunter",
      "Regina Commander",
      "Sa'Nasi Merc",
      "Sa'Nasi Guard",
      "Spiran",
      "Spiran Mate",
      "Calm Xenu",
      "Battle Ready Xenu",
    ]
    let selectedRace = races[Math.floor(Math.random() * races.length)]
    return selectedRace
  }

  const selectRandomBackground = () => {
    let backgrounds = [
      'Olive',
      'Emerald',
      'Dark Cyan',
      'Dark Purple',
      'Dark Pink',
      'Fushia',
      'Brown',
      'Khaki',
      'Apple',
      'Pink',
      'Cyan',
      'Blue',
      'Red',
      'Green',
      'Purple',
      'Yellow',
      'Orange',
      'Black',
      'Grey',
      'Fight Room'
    ]

    let background = backgrounds[Math.floor(Math.random() * backgrounds.length)]
    return background
  }

  const generateImage = async (identifyingHash, s3, race, background) => {
    var data = JSON.stringify({
      action: 'generateOGAstral',
      race: race,
      background: background,
      identifyingHash: identifyingHash
    });
    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/astralmint",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let imageLink = await axios(config).then(response => {
      // console.log(response)
      return response.data.imageLink
    })
    .catch(error => {
      console.log(error)
      return 0
    })
    return imageLink
  }
  const generateMetadata = async(nft) => {
    const s3Config = {
      bucketName:"maxinbodyshop",
      region: "us-east-2",
      accessKeyId: process.env.AWS_KEY,
      secretAccessKey: process.env.AWS_SECRET,
      s3Url: 'https://maxinbodyshop.s3.amazonaws.com'
    }
    const ReactS3Client = new S3(s3Config);

    let race = selectRandomRace()
    let background = selectRandomBackground()
    let newImage = await generateImage(nft.identifyingHash, ReactS3Client, race, background)
    if (newImage){
      let name = "Astrals Anima #" + nft.id.toString()
      let metadata = {
        "name": name,
        "symbol": "ASTRAL",
        "description": "Astrals Anima are galactic warriors you equip to represent yourself, and prepare for battle.  Project yourself through your Astral.  Your inner self, your soul, in Astrals.",
        "seller_fee_basis_points": 500,
        "image": newImage,
        "attributes":[
          {"trait_type": "Background", "value": background},
          {"trait_type":"Race","value": race},
          {"trait_type":"Top","value":"None"},
          {"trait_type":"Bottom","value":"None"},
          {"trait_type":"Feet","value":"None"},
          {"trait_type":"Legendary","value":"None"},
          {"trait_type":"Class","value":"Rookie"},
          {"trait_type":"XYON","value":"0"}
        ],
        "properties":{
          "files":[
            {
              "uri": newImage,
              "type":"image/png"
            }
          ],
          "category":"image",
          "creators":[
            {
              "address":"HGTqPujVWsf6jRxr8FAEXQWpWrGAD4idxaJ5jxYRK9mB",
              "share": 100
            }
          ]
        }
      }
      let jsonName = "astrals/nfts/jsons/" + nft.identifyingHash + ".json"
      let jsonLink = "https://maxinbodyshop.s3.us-east-2.amazonaws.com/" + jsonName

      await ReactS3Client.uploadFile(JSON.stringify(metadata), jsonName).then( async response => {
        var data = JSON.stringify({
          action: 'setJSON',
          jsonLink: jsonLink,
          id: nft.id,
          race: race,
          background: background,
          imageLink: newImage
        });

        var config = {
          method: "post",
          url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/astralmint",
          headers: {
            "x-api-key": process.env.GATEWAY_KEY,
            "Content-Type": "application/json",
          },
          data: data,
        };

        await axios(config)
        return 1
      })

      return {jsonLink: jsonLink, imageLink: newImage, race: race, background: background}

    }
    else {
      return 0
    }
  }

  // const createSFT = async (dict, nft) => {
  //   const quicknodeURL = process.env.QUICKNODE;
  //   const solanaConnection = new Connection(quicknodeURL);
  //   const astralWallet = Keypair.fromSecretKey(bs58.decode(process.env.ASTRAL_WALLET));
  //   let metaplex = Metaplex.make(solanaConnection).use(walletAdapterIdentity(wallet))
  //   let collectionNFT = new PublicKey("NRBM7jS2Ep1z1jpZTWiTqbq9Lhv4PQSjSVEQd9PY7e2")
  //
  //   let nftName = "Astrals Anima #" + nft.id.toString()
  //   let newNFT = ''
  //   let address = ''
  //   let signature = 'notAvailable'
  //
  //   try {
  //     newNFT = await metaplex.nfts().create({
  //         uri: dict.jsonLink,
  //         name: nftName,
  //         sellerFeeBasisPoints: 500,
  //         isCollection: 1,
  //         collection: collectionNFT,
  //         updateAuthority: astralWallet,
  //         tokenOwner: publicKey
  //     });
  //     address = newNFT.nft.address.toBase58()
  //     signature = newNFT.response.signature
  //
  //   } catch (error) {
  //     const errorString = error.toString();
  //     console.log(errorString)
  //     const regex = /\[(.*?)\]/g;
  //     const matches = errorString.match(regex);
  //     address = matches[2].substring(1, matches[2].length - 1);
  //     console.log(address)
  //   }
  //
  //   var data = JSON.stringify({
  //     action: "storeNFT",
  //     id: nft.id,
  //     userWallet: publicKey.toBase58(),
  //     identifyingHash: nft.identifyingHash,
  //     nftHash: address,
  //     creationSignature: signature,
  //     raceName: dict.race,
  //     background: dict.background,
  //     metadataLink: dict.jsonLink,
  //     imageLink: dict.imageLink
  //   });
  //   var config = {
  //     method: "post",
  //     url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/astralmint",
  //     headers: {
  //       "x-api-key": process.env.GATEWAY_KEY,
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };
  //   await axios(config)
  //   .then(async (response) => {
  //     console.log(response)
  //   })
  //   .catch(error => {
  //     console.log(error)
  //   })
  //
  //   return newNFT
  // }
  // const mintNFT = useCallback(async () => {
  //   try {
  //     if (!publicKey) throw new WalletNotConnectedError();
  //     let identifyingHash = randomHash(50);
  //     setPopup(true);
  //     setPopupState("paymentWaiting")
  //     const quicknodeURL = process.env.QUICKNODE;
  //     const solanaConnection = new Connection(quicknodeURL);
  //
  //     var transaction = new web3.Transaction().add(
  //       web3.SystemProgram.transfer({
  //         fromPubkey: publicKey,
  //         toPubkey: "HGTqPujVWsf6jRxr8FAEXQWpWrGAD4idxaJ5jxYRK9mB",
  //         lamports: web3.LAMPORTS_PER_SOL * .00002,
  //       })
  //     )
  //
  //     const latestBlockhash = await solanaConnection.getLatestBlockhash();
  //     transaction.recentBlockhash = latestBlockhash.blockhash;
  //     transaction.feePayer = publicKey
  //
  //     let signed = await signTransaction(transaction);
  //     try{
  //       const signature = await solanaConnection.sendRawTransaction(signed.serialize())
  //       var data = JSON.stringify({
  //         action: "storePayment",
  //         userWallet: publicKey.toBase58(),
  //         signature: signature,
  //         identifyingHash: identifyingHash
  //       });
  //       var config = {
  //         method: "post",
  //         url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/astralmint",
  //         headers: {
  //           "x-api-key": process.env.GATEWAY_KEY,
  //           "Content-Type": "application/json",
  //         },
  //         data: data,
  //       };
  //       axios(config)
  //       .then(async (response) => {
  //         setPopupState("generatingJSON")
  //         let hashID = response.data.id
  //         let nftTemp = {
  //           id: hashID,
  //           imageLink: 0,
  //           identifyingHash: identifyingHash
  //         }
  //
  //         let metadataReturn = await generateMetadata(nftTemp)
  //         console.log(metadataReturn)
  //         setPopupState("creatingNFT")
  //
  //         let nftData = await createSFT(metadataReturn, nftTemp)
  //         setPopupState("completed")
  //         // setPopup(false)
  //       })
  //       .catch(error => {
  //         console.log(error)
  //         setPopupState("transactionError")
  //         // setPopupState("ISSUE SAVING THE TRANSACTION INTO THE DATABASE")
  //       })
  //
  //     }
  //     catch (error){
  //     console.log(error)
  //     setPopupState("transactionError")
  //   }
  // } catch (error){
  //   console.log(error)
  //   setPopupState("transactionError")
  // }
  //
  // }, [publicKey, connection, selectedNFT, newMetadataID, metadata, metadataLink, sortedData]);

  const createNewSFT = async (dict, nft) => {
    const quicknodeURL = process.env.QUICKNODE;
    const solanaConnection = new Connection(quicknodeURL);

    const tokenPublicKey = await new web3.PublicKey('DW9NpVZryg3PBiN1m1M3mm3p9G7kbhosKHzy8aPNhkGz');
    const astralWallet = await new web3.PublicKey("HGTqPujVWsf6jRxr8FAEXQWpWrGAD4idxaJ5jxYRK9mB");
    const feeWallet = await new web3.PublicKey(
      "DdFBV8t6xeACpG7R7whMp7HoCd5QtQGgs5NCoct3Bqix"
    );

    const walletKey = web3.Keypair.fromSecretKey(bs58.decode(process.env.ASTRAL_WALLET));
    const destinationWalletCoinAccount = await getAssociatedTokenAddress(
      tokenPublicKey,
      astralWallet
    );

    let coin_account;

    try {
      coin_account = await getAccount(connection, destinationWalletCoinAccount);
    }
    catch (error) {
      try {
        // replaced creatorDestination with astralWallet - this is creating the account if the account doesnt already exist (shouldnt hit this)
        const transaction = new web3.Transaction().add(
          createAssociatedTokenAccountInstruction(
            publicKey,
            destinationWalletCoinAccount,
            astralWallet,
            tokenPublicKey,
            TOKEN_PROGRAM_ID,
            ASSOCIATED_TOKEN_PROGRAM_ID
          )
        );
        const signature = await sendTransaction(transaction, solanaConnection);
        await solanaConnection.confirmTransaction(signature, "processed");
      }
      catch (error) {
        console.log("error 1")
        throw error;
      }
    }

    const sourceWalletCoinAccount = await getAssociatedTokenAddress(
      tokenPublicKey,
      publicKey
    );

    let tokenInfo = await solanaConnection.getTokenSupply(tokenPublicKey)
    let decimal = tokenInfo.value.decimals
    let finalDecimal = 10 ** decimal

    var transaction = new web3.Transaction().add(
      createTransferInstruction(
        sourceWalletCoinAccount,
        destinationWalletCoinAccount,
        publicKey,
        1 * finalDecimal,
        [],
        TOKEN_PROGRAM_ID
      )
    );
    // transaction.add(
    //   web3.SystemProgram.transfer({
    //     fromPubkey: publicKey,
    //     toPubkey: feeWallet,
    //     lamports: web3.LAMPORTS_PER_SOL * 0.00003,
    //   })
    // );

    let metaplex = Metaplex.make(solanaConnection).use(walletAdapterIdentity(wallet))
    let collectionNFT = new PublicKey("666Cs7b775wTmPgZMKi9FAsCjPcoQ5CcLYPX8wfbNWRV")

    let nftName = "Astrals Anima #" + nft.id.toString()

    let createNftBuilder = await metaplex.nfts().builders().create({
        uri: dict.jsonLink,
        name: nftName,
        symbol: 'ASTRAL',
        sellerFeeBasisPoints: 500,
        primarySaleHappened: 1,
        collection: collectionNFT,
        collectionAuthority: walletKey,
        updateAuthority: walletKey,
        tokenOwner: publicKey
    });
    // console.log(metaplex.identity())
    transaction.add(...createNftBuilder.getInstructions())

    let mintAddress = createNftBuilder.getContext().mintAddress
    // console.log(mintAddress?.toString())
    const mintSigner = createNftBuilder.getSigners().filter(
        (signer) => signer.publicKey.toString() == mintAddress?.toString()
      )

    const latestBlockhash = await solanaConnection.getLatestBlockhash();
    transaction.feePayer = publicKey;
    transaction.recentBlockhash = latestBlockhash.blockhash;

    let sendSigned;
    try {
      sendSigned = await signTransaction(transaction);
      sendSigned.partialSign(mintSigner[0]);
      sendSigned.partialSign(walletKey);
    }
    catch (error) {
      console.log("error 3")
      throw error;
    }

    try {
      const signature = await solanaConnection.sendRawTransaction(sendSigned.serialize(), { skipPreflight: true });
      await storeNFT(dict, nft, mintAddress?.toString(), signature, publicKey.toBase58());
      const sent = await solanaConnection
      .confirmTransaction({
          blockhash: latestBlockhash.blockhash,
          lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
          signature: signature,
        })
        .then(async (sentData) => {
          // console.log(sentData, signature)
          setPopupState("completed")
        });
    }
    catch (error) {
      console.log("error 4")
      throw error;
    }
  }
  const storeNFT = async (dict, nft, address, signature, userWallet) => {
    var data = JSON.stringify({
      action: "storeNFT",
      id: nft.id,
      userWallet: userWallet,
      identifyingHash: nft.identifyingHash,
      nftHash: address,
      creationSignature: signature,
      raceName: dict.race,
      background: dict.background,
      metadataLink: dict.jsonLink,
      imageLink: dict.imageLink
    });
    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/astralmint",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
    .then(async (response) => {
      // console.log(response)
    })
    .catch(error => {
      console.log(error)
    })
  }
  const newMintNFT = useCallback(async () => {
    try {
      if (!publicKey) throw new WalletNotConnectedError();
      let identifyingHash = randomHash(50);
      var data = JSON.stringify({
        action: "initiateMint",
        userWallet: publicKey.toBase58(),
        identifyingHash: identifyingHash
      });
      var config = {
        method: "post",
        url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/astralmint",
        headers: {
          "x-api-key": process.env.GATEWAY_KEY,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
      .then(async (response) => {
        setPopup(true)
        setPopupState("generatingJSON")
        let hashID = response.data.id - 207
        let nftTemp = {
          id: hashID,
          imageLink: 0,
          identifyingHash: identifyingHash
        }

        let metadataReturn = await generateMetadata(nftTemp)
        if (metadataReturn){
          setPopupState("creatingNFT")

          let nftData = await createNewSFT(metadataReturn, nftTemp)
          // setPopupState("completed")
        } else {
          console.log('metadata creation failure')
          setPopupState("transactionError")
        }
        // setPopupState("creatingNFT")
        //
        // let nftData = await createSFT(metadataReturn, nftTemp)
        // setPopupState("completed")
        // setPopup(false)
      })
      .catch(error => {
        console.log(error)
        setPopupState("transactionError")
      })
      // let nftTemp = {
      //   id: 0,
      //   imageLink: 0,
      //   identifyingHash: identifyingHash
      // }
      // let metadataReturn = await generateMetadata(nftTemp)
      // let nftData = await createNewSFT(metadataReturn, nftTemp)
    }
    catch (error){
      console.log(error)
      setPopupState("transactionError")
    }
  }, [publicKey, connection, selectedNFT, newMetadataID, metadata, metadataLink, sortedData]);

  const confirmMutation = async (nftHash, publicKey, signature) => {
    // // console.log(selectedTrait)
    var data = JSON.stringify({
      action: "confirmTransformation",
      projectID: props.projectID,
      purchasingWallet: publicKey,
      transactionID: signature,
      hash: nftHash,
      transactionType: 'fusion'
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/traitpurchase",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let returnResponse = axios(config)
      .then(function (response) {
        return true;
      })
      .catch(function (error) {
        // // console.log(error);
        return false;
      });

    return returnResponse;
  };

  const renderPopup = () => {
    if (popupState === "paymentWaiting") {
      return (
        <div className="fixed bottom-48 lg:bottom-24 left-1/2 transform -translate-x-1/2">
          <div className="relative">
            <img
              className="w-[500px] 2xl:w-[600px]"
              src={PopupMint}
              alt="Logo"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <p className="text-white text-lg sm:text-xl md:text-3xl lg:text-3xl font-title-bold ml-8 sm:ml-24 ellipsis">Waiting <br></br>On Payment</p>
            </div>
          </div>
        </div>

      );
    }
    else if(popupState === "generatingJSON"){
      // // // console.log("got here error")
      return (
        <div className="fixed bottom-48 lg:bottom-24 left-1/2 transform -translate-x-1/2">
          <div className="relative">
            <img
              className="w-[500px] 2xl:w-[600px]"
              src={PopupMint}
              alt="Logo"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <p className="text-white text-lg sm:text-xl md:text-3xl lg:text-3xl font-title-bold ml-8 sm:ml-24 ellipsis">Generating <br></br>The JSON</p>
            </div>
          </div>
        </div>

      );
    }
    else if(popupState === "creatingNFT"){
      // // // console.log("got here error")
      return (
        <div className="fixed bottom-48 lg:bottom-24  left-1/2 transform -translate-x-1/2">
          <div className="relative">
            <img
              className="w-[500px] 2xl:w-[600px]"
              src={PopupMint}
              alt="Logo"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <p className="text-white text-lg sm:text-xl md:text-3xl font-title-bold ml-8 sm:ml-24 ellipsis">Creating <br></br>The NFT</p>
            </div>
          </div>
        </div>

      );
    }
    else if(popupState === "completed"){
      return (
        <div className="fixed bottom-48 lg:bottom-24  left-1/2 transform -translate-x-1/2">
          <div className="relative">
            <img
              className="w-[500px] 2xl:w-[600px]"
              src={PopupMint}
              alt="Logo"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <p className="text-white text-lg sm:text-xl md:text-3xl font-title-bold ml-8 sm:ml-24">Success! <br></br>Astral Minted.</p>
            </div>
            <button
              onClick={() => {
                resetPopup();
              }}
              className="absolute top-2 right-2 text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
            >
              &#10761;
            </button>
          </div>
        </div>

      );
    }
    else if(popupState === "transactionError"){
      // // // console.log("got here error")
      return (
        <div className="fixed bottom-48 lg:bottom-24  left-1/2 transform -translate-x-1/2">
          <div className="relative">
            <img
              className="w-[500px] 2xl:w-[600px]"
              src={PopupMint}
              alt="Logo"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <p className="text-white text-lg sm:text-xl md:text-3xl font-title-bold ml-8 sm:ml-24">Payment <br></br>Issue</p>
            </div>
            <button
              onClick={() => {
                resetPopup();
              }}
              className="absolute top-2 right-2 text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray"
            >
              &#10761;
            </button>
          </div>
        </div>

      );
    }
  };

  // const continueProcess = async (nft) => {
  //   setPopup(true)
  //   if (nft.status === "generateJSON"){
  //     setPopupState("GENERATING JSON")
  //     let metadataReturn = await generateMetadata(nft)
  //     setPopupState("CREATING NFT")
  //     let nftData = await createSFT(metadataReturn, nft)
  //     setPopupState("CREATED")
  //   }
  //
  //   else if (nft.status === "mintNFT"){
  //     setPopupState("CREATING NFT")
  //     let dictTemp = {
  //       jsonLink: nft.jsonLink,
  //       race: nft.race,
  //       background: nft.background
  //     }
  //     let nftData = await createSFT(dictTemp, nft)
  //     setPopupState("CREATED")
  //   }
  // }
  // const renderPending = () => {
  //   return (
  //     <div>
  //       Left to Create:
  //       <br></br>
  //       { pendingNFTs?.map((nft) => (
  //           <div>
  //             <button className='bg-white' onClick={() => {continueProcess(nft)}}>
  //               Finalize
  //             </button>
  //           </div>
  //       ))}
  //     </div>
  //   )
  // }

  const resetPopup = async () => {
    setPopup(false);
    setPopupState("");
  };

  const reloadData = () => {
    setReadyToUpgrade(false)
    setFetchedWalletNFTs(false)
    setFilteredProjectNFTs(false)
    setSelectedNFT()
    setPopup(false);
    setPopupState("default");
  }

  const renderNFTs = () => {
    return(
      <>
      {
        Object.keys(userProjectNFTs)?.length ?
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {Object.keys(userProjectNFTs)
            .map((nft) => {
              return (
                <div>
                  <img
                    src={userProjectNFTs[nft].image}
                    onClick={() => setUpgradeNFT({...userProjectNFTs[nft], hash: nft})}
                    className={`w-full cursor-pointer border-2 ${
                      selectedNFT === nft
                        ? "border-primary-red"
                        : "border-black"
                    } p-1`}
                    style={{ marginTop: 10, borderRadius: 10 }}
                  />
                  <p
                    className={
                      "text-center pt-2 text-gray-deep uppercase font-gilroy-bold text-[12px]"
                    }
                  >
                    {userProjectNFTs[nft].name}
                  </p>
                </div>
              );
            })}
        </div>
        :
        <p className="text-center pb-5">You don't have any NFTs eligible for mutation in your wallet</p>
      }
      </>
    )
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-[90vh] relative rounded-3xl"
      style={{
        backgroundImage: `url(${MintBG})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover', // Add this line
        backgroundRepeat: 'no-repeat' // Add this line
      }}
    >
      {popup ? renderPopup() : ""}
      <div class="p-4">
        <img onClick={() => newMintNFT()} src={MintBtnOff} class="hover:cursor-pointer mintBtn w-64 2xl:w-80"/>
      </div>
      <div className="absolute bottom-0 right-0 mb-12 pr-12">
        <img
          className="hover:cursor-pointer traitBackBtn w-64 2xl:w-80"
          src={BackTraitsOff}
          alt="Logo"
          onClick={() => props.setPage("astralTraits")}
        />
      </div>
    </div>

  );
};


export default AstralsMint;
