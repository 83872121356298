import React, { useState, useEffect, useCallback, useRef } from "react";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import * as web3 from "@solana/web3.js";
import axios from "axios";
import Loader from "../img/loading.gif";
import Failed from "../img/failedtransaction.png";
import V1ButtonActive from "../img/v1ButtonActive.png";
import V1ButtonInactive from "../img/v1ButtonNonActive.png";
import V2ButtonActive from "../img/v2ButtonActive.png";
import V2ButtonInactive from "../img/v2ButtonNonActive.png";
import BuyNowActive from "../img/buyNowActive.png";
import BuyNowInactive from "../img/buyNowNonActive.png";
import LegBuyNowActive from "../img/legendaryBuyActive.png";
import LegBuyNowInactive from "../img/legendaryBuyNowNotActive.png";
import RightArrowActive from "../img/rightArrowActive.png";
import RightArrowInactive from "../img/rightArrowNotActive.png";
import LeftArrowActive from "../img/leftArrowActive.png";
import LeftArrowInactive from "../img/leftArrowNotActive.png";
import LegRightArrowInactive from "../img/legendaryRightNotActive.png";
import LegLeftArrowInactive from "../img/legendaryLeftNotActive.png";
import DestructorBadge from "../img/destructorBadge.png";
import EliteBadge from "../img/eliteBadge.png";
import RookieBadge from "../img/rookieBadge.png";
import SoldierBadge from "../img/soldierBadge.png";
import XyonsImage from "../img/xyonsImage.png";
import StatusBar from "../img/statusBar.png";
import StatusBarZero from "../img/statusBarZero.png";
import StatusBarTwentyFive from "../img/statusBarTwentyFive.png";
import StatusBarFifty from "../img/statusBarFifty.png";
import StatusBarSeventyFive from "../img/statusBarSeventyFive.png";
import MoreInfoInactive from "../img/moreInfoArrowNotActive.png";
import MoreInfoActive from "../img/moreInfoArrowActive.png";
import PopupInfo from "../img/infoPopupBack.png";
import RedPopup from "../img/redPopup.png";
import GreenPopup from "../img/greenPopup.png";
import PopupPause from "../img/pausePopup.png";
import CustomizeBtn from "../img/customize-btn.png";
import CustomizeBG from "../img/customizer-bg.png";
import CustomizePanelBtn from '../img/customizer-btn.png'
import BazaarBtn from "../img/goToBazaar.png";
import ResetBtn from "../img/reset-off.png";
import PopupIneligible from '../img/popupIneligible.png'
import PopupNoFunds from '../img/popupNoFunds.png'
import PopupSuccessfulPurchase from '../img/popupSuccessfulPurchase.png'
import PopupTransactionError from '../img/popupTransactionError.png'
import PopupWalletPrompt from '../img/popupWalletPrompt.png'
import PopupInternalError from '../img/popupInternalError.png'
import ChooseNFT from '../img/chooseNFT-off.png';
import ResetTraits from '../img/resetTraits-off.png'

import './AstralTrait.css'

import bs58 from "bs58";
import {
  MINT_SIZE,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createInitializeMintInstruction,
  getMinimumBalanceForRentExemptMint,
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  createMintToInstruction,
  getAccount,
  createTransferCheckedInstruction,
  createAssociatedTokenAccount
} from '@solana/spl-token';

import { createCreateMetadataAccountV2Instruction, createVerifyCollectionInstruction } from '@metaplex-foundation/mpl-token-metadata';
import {
  findMetadataPda,
  findMasterEditionV2Pda,
  Metaplex,
  keypairIdentity
} from '@metaplex-foundation/js';

import { getParsedNftAccountsByOwner } from "@nfteyez/sol-rayz";

import ProgressBar from "@ramonak/react-progress-bar";
import TraitsHeader from "../components/TraitsHeader";
import TraitItem from "../components/TraitItem";
import { BuyNowBtn, P } from "../components/UI/StyledComponents";
import { useTheme } from "styled-components";
import SemiCricle from "../components/UI/SemiCricle";
import NotAllowed from "../components/UI/not_allowed.png";


const CustomizeAstral = (props) => {
  const theme = useTheme();

  //internal navigation
  const [page, setPage] = useState("bodyshop");
  const [selectedTraitCategory, setSelectedTraitCategory] = useState("All Traits");
  const [traitCategories, setTraitCategories] = useState([]);

  // nft selection
  const [selectedNFT, setSelectedNFT] = useState(false)
  const [showNFTPopup, setShowNFTPopup] = useState(false);

  const [fetchedWalletNFTs, setFetchedWalletNFTs] = useState(false)
  const [walletNFTs, setWalletNFTs] = useState([])
  const [userProjectNFTs, setUserProjectNFTs] = useState([])

  const [fetchedProjectNFTs, setFetchedProjectNFTs] = useState(false)
  const [projectNFTs, setProjectNFTs] = useState({})

  const [filteredProjectNFTs, setFilteredProjectNFTs] = useState(false)
  const [eligibleClasses, setEligibleClasses] = useState([])

  // image builder
  const [assetDict, setAssetDict] = useState({})
  const [imageArray, setImageArray] = useState([])
  const [currentImageLink, setCurrentImageLink] = useState();

  const [upgradingProjectNFTs, setUpgradingProjectNFTs] = useState([])
  const [upgradeError, setUpgradeError] = useState(false)

  // web3 transactions
  const { connection } = useConnection();
  const { publicKey, sendTransaction, signTransaction } = useWallet();

  // purchasing popups
  const [popup, setPopup] = useState(false);
  const [popupState, setPopupState] = useState("");
  const [sentStatus, setSentStatus] = useState("default");

  // trait details
  const [traitDict, setTraitDict] = useState({})
  const [soldOutTraits, setSoldOutTraits] = useState([])
  const [allTraits, setAllTraits] = useState([]);
  const [selectedTrait, setSelectedTrait] = useState();
  const [currentRaceVersion, setCurrentRaceVersion] = useState("V1")
  const [gatingHash, setGatingHash] = useState();
  const [hasGated, setHasGated] = useState(false)
  const [allClaimTraits, setAllClaimTraits] = useState()
  const [swapTraitsArray, setSwapTraitsArray] = useState([])

  // imageCustomization
  const [backgroundOptions, setBackgroundOptions] = useState()
  const [backgroundTrait, setBackgroundTrait] = useState()
  const [raceTrait, setRaceTrait] = useState()
  const [topTrait, setTopTrait] = useState()
  const [bottomTrait, setBottomTrait] = useState()
  const [feetTrait, setFeetTrait] = useState()
  const [legendaryTrait, setLegendaryTrait] = useState()
  const [raceIndex, setRaceIndex] = useState(0)
  const [topIndex, setTopIndex] = useState(0)
  const [bottomIndex, setBottomIndex] = useState(0)
  const [feetIndex, setFeetIndex] = useState(0)
  const [legendaryIndex, setLegendaryIndex] = useState(0)
  const [backgroundIndex, setBackgroundIndex] = useState(0)


  const [sortedData, setSortedData] = useState([])
  const [applicableTraitDict, setApplicableTraitDict] = useState({})
  const [filteredUserTraits, setFilteredUserTraits] = useState(false)
  const [userTraitDict, setUserTraitDict] = useState({})

  const [changingBackgroundTrait, setChangingBackgroundTrait] = useState(false)
  const [sendRaceTrait, setSendRaceTrait] = useState(false)
  const [sendFeetTrait, setSendFeetTrait] = useState(false)
  const [sendBottomTrait, setSendBottomTrait] = useState(false)
  const [sendTopTrait, setSendTopTrait] = useState(false)
  const [sendLegendaryTrait, setSendLegendaryTrait] = useState(false)
  const [choseTrait, setChoseTrait] = useState(false)
  const [showClaimTraits, setShowClaimTraits] = useState(false)
  const [pickingBackground, setPickingBackground] = useState(false)

  const [readyToUpgrade, setReadyToUpgrade] = useState(false)

  const [selectedNFTClass, setSelectedNFTClass] = useState()
  const [selectedNFTXyon, setSelectedXyon] = useState()
  const [percentageStatus, setPercentageStatus] = useState()

  const imgRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const timeoutRef = useRef(null);

  const glxyToken = 'CJ5U6wPmjxFUyTJpUTS7Rt1UqhTmSVRMvmJ8WD4nndXW'

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const aspectRatio = windowDimensions.width / windowDimensions.height;

  useEffect(() => {
    setUserProjectNFTs([])
    setWalletNFTs([])
    // setSwapTraitsArray([])
    setFetchedWalletNFTs(false)
    setFetchedProjectNFTs(false)
    setFilteredProjectNFTs(false)
    // setShowClaimTraits(false)

  }, [publicKey])

  useEffect(() => {
    if (publicKey && !fetchedWalletNFTs){
      const getNFTs = async () => {
        let myNfts = await getParsedNftAccountsByOwner({
          publicAddress: publicKey.toBase58(),
          connection: connection,
          serialization: true,
        });
        let walletDictTemp = {}
        myNfts.forEach((nft) => {
          walletDictTemp[nft.mint] = nft.data.uri
        });
        setFetchedWalletNFTs(true)
        setWalletNFTs(myNfts)
      };
      getNFTs();
    }

    if (publicKey && !fetchedProjectNFTs){
      var data = JSON.stringify({
        projectID: props.projectID,
        action: "getCurrentNFTs",
        userWallet: publicKey.toBase58()
      });

      var config = {
        method: "post",
        url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/astralmint",
        headers: {
          "x-api-key": process.env.GATEWAY_KEY,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log(response)
          setProjectNFTs(response.data.nfts)
          setAssetDict(response.data.assetDict)
          setBackgroundOptions(response.data.assetDict.Background)
          setSoldOutTraits(response.data.soldOutTraits)
          setTraitDict(response.data.traitDict)
          setApplicableTraitDict(response.data.applicableTraitDict)
          setFetchedProjectNFTs(true)
          setAllClaimTraits(response.data.swapTraitArray)
        })
        .catch(function (error) {
          // // console.log(error);
        });
    }
  }, [fetchedWalletNFTs, fetchedProjectNFTs, publicKey])

  useEffect(() => {
    if (walletNFTs.length && fetchedWalletNFTs && fetchedProjectNFTs && !filteredProjectNFTs){
      const filterUserNFTs = async () => {
        let ownedNFTDictTemp = {}

        await Promise.all(walletNFTs.map(async (nft) => {
          if (Object.keys(projectNFTs).includes(nft.mint)) {
            ownedNFTDictTemp[nft.mint] = {...projectNFTs[nft.mint]}
          }
        }))
        setUserProjectNFTs(ownedNFTDictTemp)
        setFilteredProjectNFTs(true)
      };
      filterUserNFTs();
    }
  }, [walletNFTs, fetchedProjectNFTs, fetchedWalletNFTs, filteredProjectNFTs])

  useEffect(() => {
    if (walletNFTs.length && fetchedWalletNFTs && fetchedProjectNFTs && !filteredUserTraits){
      const filterUserTraits = async () => {
        let ownedTraitsTemp = {
          "Background": backgroundOptions,
          "Feet": [],
          "Top": [],
          "Bottom": [],
          "Legendary": []
        }
        await Promise.all(walletNFTs.map(async (nft) => {
          if (Object.keys(applicableTraitDict).includes(nft.mint)) {
            if (Object.keys(ownedTraitsTemp).includes(applicableTraitDict[nft.mint].category)){
              ownedTraitsTemp[applicableTraitDict[nft.mint].category].push({...applicableTraitDict[nft.mint], hash: nft.mint})
            }
            else{
              ownedTraitsTemp[applicableTraitDict[nft.mint].category] = [{...applicableTraitDict[nft.mint], hash: nft.mint}]
            }
          }
        }))
        setUserTraitDict(ownedTraitsTemp)
        setFilteredUserTraits(true)
      };
      filterUserTraits();
    }
  }, [walletNFTs, fetchedProjectNFTs, fetchedWalletNFTs, filteredUserTraits])

  useEffect(() => {
      if (selectedNFT && choseTrait){
        generateImage(false, [])
    }
  }, [selectedNFT, raceTrait, backgroundTrait, topTrait, bottomTrait, feetTrait, legendaryTrait]);

  function handleResize() {
    if (imgRef.current) {
      setDimensions({
        width: imgRef.current.offsetWidth * 0.8,
        height: imgRef.current.offsetHeight * 0.9
      });
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      // Cleanup the event listener
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(()=> {
    // console.log(userProjectNFTs)
    // console.log(bottomTrait, feetTrait, backgroundTrait, topTrait, legendaryTrait, raceTrait)
  }, [bottomTrait, feetTrait, backgroundTrait, topTrait, legendaryTrait, raceTrait])

  const generateImage = (start, startingAttributeArray) => {

    let newAttributeList = []

    if (start){
      newAttributeList = [...startingAttributeArray]
    }
    else{
      newAttributeList.push(backgroundTrait)
      newAttributeList.push(raceTrait)
      newAttributeList.push(feetTrait)
      newAttributeList.push(bottomTrait)
      newAttributeList.push(topTrait)
      newAttributeList.push(legendaryTrait)
    }

    var data = JSON.stringify({
      "action": "setCustomizerImage2",
      "projectID": props.projectID,
      "selectedTrait": {...selectedTrait, cosmetic: 0},
      "attributes": newAttributeList,
      "selectedNFT": {...userProjectNFTs[selectedNFT], hash: selectedNFT},
      "collectionName": "Base",
      "swap": 0
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/astralmint',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      if (!response.data.error && !("errorMessage" in response.data)) {
        // console.log(response, userTraitDict)
        setImageArray(response.data.imageArray)
        setReadyToUpgrade(true)
      }
      else {
        setUpgradeError(true);
      }
    })
    .catch(function (error) {
      // // console.log(error);
    });
  }

  const checkEligibility = (traitArray) => {
    let eligible = true
    // console.log(eligibleClasses, traitArray)
    traitArray.map(trait => {
      // console.log(trait, eligibleClasses)
      if (!eligibleClasses.includes(trait.requiredClass)){
        eligible = false
      }
    })

    return eligible
  }

  const randomHash = (length) => {
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let str = "";
    for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
  };

  const generateMetadata = () => {
    let metadataTemp = {}
     metadataTemp.Background = backgroundTrait
     metadataTemp.Race = raceTrait
     metadataTemp.Feet = feetTrait
     metadataTemp.Bottom = bottomTrait
     metadataTemp.Top = topTrait
     metadataTemp.Legendary = legendaryTrait

     return metadataTemp
  }

  const getSwappedTraits = async (upgradeMetadata, oldLink) => {
    let swappedTraits = []
    try {
      const response = await axios.get(oldLink)
      // console.log(response)
      response.data.attributes
        .filter((attribute) => attribute['trait_type'] !== "Background" && attribute['trait_type'] !== "Class" && attribute['trait_type'].toLowerCase() !== 'xyon')
        .map((attribute) => {
          // if (attribute['trait_type'] === "Legendary" && upgradeMetadata[attribute['trait_type']]['value'] !== attribute['value']){
          //   swappedTraits = [attribute]
          // }
          if (upgradeMetadata[attribute['trait_type']]['value'] !== attribute['value']){
            swappedTraits.push(attribute)
          }
        })
      response.data.attributes
        .filter((attribute) => attribute['trait_type'] === "Legendary")
        .map((attribute) => {
          if (upgradeMetadata[attribute['trait_type']]['value'] !== attribute['value']){
            swappedTraits = [attribute]
          }
        })
    } catch (error) {
      console.log(error);
    }
    return swappedTraits
  }
  const getChangedBackground = async (upgradeMetadata, oldLink) => {
    let changedBackground = false
    try {
      const response = await axios.get(oldLink)
      response.data.attributes
        .filter((attribute) => attribute['trait_type'] === "Background")
        .map((attribute) => {
          // console.log(attribute)
          if (upgradeMetadata[attribute['trait_type']]['value'] !== attribute['value']){
            changedBackground = true
          }
        })
    } catch (error) {
      console.log(error);
    }
    return changedBackground
  }
  const getChangedLegendary = async (upgradeMetadata, oldLink) => {
    let changedLegendary = false
    try {
      const response = await axios.get(oldLink)
      response.data.attributes
        .filter((attribute) => attribute['trait_type'] === "Legendary")
        .map((attribute) => {
          // console.log(attribute)
          if (upgradeMetadata[attribute['trait_type']]['value'] !== attribute['value']){
            changedLegendary = true
          }
        })
    } catch (error) {
      console.log(error);
    }
    return changedLegendary
  }

  const customize = useCallback(async () => {
    try {
      if (!publicKey) throw new WalletNotConnectedError();
      let identifyingHash = randomHash(50);
      let sendTraitArray = []
      let upgradeMetadata = await generateMetadata()
      let swappedTraits = await getSwappedTraits(upgradeMetadata, userProjectNFTs[selectedNFT].metadataLink)
      let changedBackground = await getChangedBackground(upgradeMetadata, userProjectNFTs[selectedNFT].metadataLink)
      let changedLegendary = await getChangedLegendary(upgradeMetadata, userProjectNFTs[selectedNFT].metadataLink)
      // console.log(changedLegendary)
      // defining which SFTs to send
      if (sendRaceTrait){
        sendTraitArray.push(raceTrait)
      }
      if (sendFeetTrait && !changedLegendary){
        sendTraitArray.push(feetTrait)
      }
      if (sendBottomTrait && !changedLegendary){
        sendTraitArray.push(bottomTrait)
      }
      if (sendTopTrait && !changedLegendary){
        sendTraitArray.push(topTrait)
      }
      if (sendLegendaryTrait && swappedTraits.length === 1){
        sendTraitArray = [legendaryTrait]
      }
      let isEligible = await checkEligibility(sendTraitArray)
      // console.log(isEligible, sendTraitArray, swappedTraits)
      if (isEligible && ((sendTraitArray.length && swappedTraits.length) || changedBackground || changedLegendary) && imageArray.length){
        setPopup(true);
        setPopupState("pauseWait");
        // console.log(upgradeMetadata)
        var data = JSON.stringify({
          action: "generateFiles",
          identifyingHash: identifyingHash,
          newMetadata: upgradeMetadata,
          imageArray: imageArray,
          currentImageLink: userProjectNFTs[selectedNFT].imageLink,
          currentMetadataLink: userProjectNFTs[selectedNFT].metadataLink,
          nftHash: selectedNFT,
          userWallet: publicKey.toBase58(),
          hashArray: sendTraitArray,
          xyon: userProjectNFTs[selectedNFT].attributeDict.Xyon,
          swappedTraits: swappedTraits
        });
        // console.log(data)
        var config = {
          method: "post",
          url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/astralmint",
          headers: {
            "x-api-key": process.env.GATEWAY_KEY,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
        .then(async response => {
          try {
            console.log(response.data)
            let jsonLink = response.data.jsonLink
            let imageLink = response.data.imageLink
            let newMetadata = response.data.newMetadata
            let metadataIDDict = response.data.metadataIDDict
            let assetIDArray = response.data.assetIDArray
            let hashArray = response.data.hashArray
            let updateClaimed = response.data.updateClaimed
            let finalSwappedTraits = response.data.finalSwappedTraits
            const astralWallet = await new web3.PublicKey("HGTqPujVWsf6jRxr8FAEXQWpWrGAD4idxaJ5jxYRK9mB");
            const feeWallet = await new web3.PublicKey("DdFBV8t6xeACpG7R7whMp7HoCd5QtQGgs5NCoct3Bqix");
            const walletKey = web3.Keypair.fromSecretKey(bs58.decode(process.env.ASTRAL_WALLET));
            const solanaConnection = new web3.Connection(process.env.QUICKNODE);

            var transaction = new web3.Transaction().add(
              web3.SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: feeWallet,
                lamports: web3.LAMPORTS_PER_SOL * 0.03,
              })
            );
            sendTraitArray.map(async trait => {
              let traitPublicKey = await new web3.PublicKey(trait.hash);
              let destinationWalletCoinAccount = await getAssociatedTokenAddress(
                traitPublicKey,
                astralWallet
              );
              let trait_account;
              try {
                trait_account = await getAccount(solanaConnection, destinationWalletCoinAccount);
              }
              catch (error) {
                try {
                  let accountTransaction = new web3.Transaction().add(
                    createAssociatedTokenAccountInstruction(
                      publicKey,
                      destinationWalletCoinAccount,
                      astralWallet,
                      traitPublicKey,
                      TOKEN_PROGRAM_ID,
                      ASSOCIATED_TOKEN_PROGRAM_ID
                    )
                  );
                  const signature = await sendTransaction(accountTransaction, solanaConnection);
                  await solanaConnection.confirmTransaction(signature, "processed");
                }
                catch (error) {
                  console.log("error 1")
                  setPopup(true);
                  setPopupState("transactionError");
                  timeoutRef.current = setTimeout(cancelPopup, 3000)
                  throw error;
                }
              }

              let userWalletTraitAccount = await getAssociatedTokenAddress(traitPublicKey, publicKey);
              transaction.add(
                createTransferCheckedInstruction(
                  userWalletTraitAccount,
                  traitPublicKey,
                  destinationWalletCoinAccount,
                  publicKey,
                  1,
                  0
                )
              );
            })
            // console.log(finalSwappedTraits.length)
            if (finalSwappedTraits.length > 0) {
              let pause = false
              let swappedTraitPublicKey = await new web3.PublicKey(finalSwappedTraits[0]);
              let destinationWalletSwapAccount = await getAssociatedTokenAddress(
                swappedTraitPublicKey,
                publicKey
              );
              let swappedTrait_account;
              try {
                swappedTrait_account = await getAccount(solanaConnection, destinationWalletSwapAccount);
              }
              catch (error) {
                try {
                  let accountTransaction = new web3.Transaction().add(
                    createAssociatedTokenAccountInstruction(
                      astralWallet,
                      destinationWalletSwapAccount,
                      publicKey,
                      swappedTraitPublicKey,
                      TOKEN_PROGRAM_ID,
                      ASSOCIATED_TOKEN_PROGRAM_ID
                    )
                  );
                  const signature = await web3.sendAndConfirmTransaction(solanaConnection,accountTransaction,[walletKey],);
                  pause = true
                }
                catch (error) {
                  console.log("error 1")
                  setPopup(true);
                  setPopupState("transactionError");
                  timeoutRef.current = setTimeout(cancelPopup, 3000)
                  throw error;
                }
              }
              if (pause){
                console.log("with 20 second pause")
                setTimeout(async () => {
                  try {
                    setPopupState("walletPrompt");
                    let userWalletTraitAccount = await getAssociatedTokenAddress(swappedTraitPublicKey, astralWallet);
                    transaction.add(
                      createTransferCheckedInstruction(
                        userWalletTraitAccount,
                        swappedTraitPublicKey,
                        destinationWalletSwapAccount,
                        astralWallet,
                        1,
                        0
                      )
                    );
                    const mintAddress = new web3.PublicKey(selectedNFT)
                    let metaplex = Metaplex.make(solanaConnection).use(keypairIdentity(walletKey))
                    const nft = await metaplex.nfts().findByMint({ mintAddress });

                    let updateNftBuilder = await metaplex.nfts().builders().update({
                      nftOrSft: nft,
                      name: nft.name,
                      uri: jsonLink
                    });
                    transaction.add(...updateNftBuilder.getInstructions())

                    try {
                      let latestBlockhash = await solanaConnection.getLatestBlockhash();
                      transaction.recentBlockhash = latestBlockhash.blockhash
                      transaction.feePayer = publicKey;
                      let sendSigned;
                      try {
                        sendSigned = await signTransaction(transaction);
                        sendSigned.partialSign(walletKey);
                      } catch (error){
                        setPopupState("transactionError");
                        timeoutRef.current = setTimeout(cancelPopup, 3000)
                        throw error;
                      }

                      const signature = await solanaConnection.sendRawTransaction(sendSigned.serialize())
                      await confirmCustomization(signature, identifyingHash, jsonLink, imageLink, newMetadata, metadataIDDict, assetIDArray, hashArray, finalSwappedTraits, updateClaimed);
                      const latestBlockhashNew = await solanaConnection.getLatestBlockhash();
                      solanaConnection
                        .confirmTransaction({
                          blockhash: latestBlockhash.blockhash,
                          lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
                          signature: signature,
                        })
                        .then(async (sentData) => {
                          console.log("success")
                          setPopup(true);
                          setPopupState("successfulPurchase");
                          timeoutRef.current = setTimeout(resetTraitShop, 5000)
                        })
                        .catch(function (error) {
                          console.log("error 2")
                          setPopup(true);
                          setPopupState("transactionError");
                          timeoutRef.current = setTimeout(cancelPopup, 3000)
                          throw error;
                        });
                    }
                    catch (error){
                      setPopup(true);
                      setPopupState("transactionError");
                      timeoutRef.current = setTimeout(cancelPopup, 3000)
                      throw error;
                    }
                  }
                  catch (error){
                    setPopup(true);
                    setPopupState("transactionError");
                    timeoutRef.current = setTimeout(cancelPopup, 3000)
                    throw error;
                  }
                }, 20000);
              }
              else {
                console.log("without 20 second pause")
                try {
                  setPopupState("walletPrompt");
                  let userWalletTraitAccount = await getAssociatedTokenAddress(swappedTraitPublicKey, astralWallet);
                  transaction.add(
                    createTransferCheckedInstruction(
                      userWalletTraitAccount,
                      swappedTraitPublicKey,
                      destinationWalletSwapAccount,
                      astralWallet,
                      1,
                      0
                    )
                  );

                  const mintAddress = new web3.PublicKey(selectedNFT)
                  let metaplex = Metaplex.make(solanaConnection).use(keypairIdentity(walletKey))
                  const nft = await metaplex.nfts().findByMint({ mintAddress });

                  let updateNftBuilder = await metaplex.nfts().builders().update({
                    nftOrSft: nft,
                    name: nft.name,
                    uri: jsonLink
                  });
                  transaction.add(...updateNftBuilder.getInstructions())

                  try {
                    let latestBlockhash = await solanaConnection.getLatestBlockhash();
                    transaction.recentBlockhash = latestBlockhash.blockhash
                    transaction.feePayer = publicKey;

                    let sendSigned;
                    try {
                      sendSigned = await signTransaction(transaction);
                      sendSigned.partialSign(walletKey);
                    } catch (error){
                      setPopupState("transactionError");
                      timeoutRef.current = setTimeout(cancelPopup, 3000)
                      throw error;
                    }

                    const signature = await solanaConnection.sendRawTransaction(sendSigned.serialize())

                    await confirmCustomization(signature, identifyingHash, jsonLink, imageLink, newMetadata, metadataIDDict, assetIDArray, hashArray, finalSwappedTraits, updateClaimed);
                    const latestBlockhashNew = await solanaConnection.getLatestBlockhash();
                    solanaConnection
                      .confirmTransaction({
                        blockhash: latestBlockhash.blockhash,
                        lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
                        signature: signature,
                      })
                      .then(async (sentData) => {
                        console.log("success")
                        setPopup(true);
                        setPopupState("successfulPurchase");
                        timeoutRef.current = setTimeout(resetTraitShop, 5000)
                      })
                      .catch(function (error) {
                        console.log("error 2")
                        setPopup(true);
                        setPopupState("transactionError");
                        timeoutRef.current = setTimeout(cancelPopup, 3000)
                        throw error;
                      });
                  }
                  catch (error){
                    console.log("error 1")
                    setPopup(true);
                    setPopupState("transactionError");
                    timeoutRef.current = setTimeout(cancelPopup, 3000)
                    throw error;
                  }
                }
                catch (error){
                  console.log("error 1")
                  setPopup(true);
                  setPopupState("transactionError");
                  timeoutRef.current = setTimeout(cancelPopup, 3000)
                  throw error;
                }
              }
            }
            else {
              setPopupState("walletPrompt");
              const mintAddress = new web3.PublicKey(selectedNFT)
              let metaplex = Metaplex.make(solanaConnection).use(keypairIdentity(walletKey))
              const nft = await metaplex.nfts().findByMint({ mintAddress });

              let updateNftBuilder = await metaplex.nfts().builders().update({
                nftOrSft: nft,
                name: nft.name,
                uri: jsonLink
              });
              transaction.add(...updateNftBuilder.getInstructions())

              try {
                let latestBlockhash = await solanaConnection.getLatestBlockhash();
                transaction.recentBlockhash = latestBlockhash.blockhash
                transaction.feePayer = publicKey;

                let sendSigned = await signTransaction(transaction);
                sendSigned.partialSign(walletKey);

                const signature = await solanaConnection.sendRawTransaction(sendSigned.serialize())
                // console.log("signature", signature)
                await confirmCustomization(signature, identifyingHash, jsonLink, imageLink, newMetadata, metadataIDDict, assetIDArray, hashArray, finalSwappedTraits, updateClaimed);
                const latestBlockhashNew = await solanaConnection.getLatestBlockhash();
                solanaConnection
                  .confirmTransaction({
                    blockhash: latestBlockhash.blockhash,
                    lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
                    signature: signature,
                  })
                  .then(async (sentData) => {
                    console.log("success")
                    setPopup(true);
                    setPopupState("successfulPurchase");
                    timeoutRef.current = setTimeout(resetTraitShop, 5000)
                  })
                  .catch(function (error) {
                    console.log("error 2")
                    setPopup(true);
                    setPopupState("transactionError");
                    timeoutRef.current = setTimeout(cancelPopup, 3000)
                    throw error;
                  });
              }
              catch (error){
                console.log("error 1")
                setPopup(true);
                setPopupState("transactionError");
                timeoutRef.current = setTimeout(cancelPopup, 3000)
                throw error;
              }
            }
          } catch (error){
            setPopup(true);
            setPopupState("transactionError");
            timeoutRef.current = setTimeout(cancelPopup, 3000)
            console.log(error)
          }

        })
        .catch(error => {
          console.log("error 1")
          setPopup(true);
          setPopupState("transactionError");
          timeoutRef.current = setTimeout(cancelPopup, 3000)
          throw error;
        })
      }
      else {
        if (!isEligible){
          console.log("error 1")
          setPopup(true);
          setPopupState("ineligible");
          timeoutRef.current = setTimeout(cancelPopup, 3000)
        } else{
          console.log("error 2")
          setPopup(true);
          setPopupState("transactionError");
          timeoutRef.current = setTimeout(cancelPopup, 3000)
        }
      }
    } catch (error){
      console.log(error)
      setPopup(true);
      setPopupState("transactionError");
      timeoutRef.current = setTimeout(cancelPopup, 3000)
    }

  }, [publicKey, connection, selectedNFT, raceTrait, backgroundTrait, topTrait, bottomTrait, feetTrait, legendaryTrait, imageArray]);

  const cancelPopup = () => {
    setPopup(false)
  }
  // log purchase into the DB
  const confirmCustomization = async ( signature, identifyingHash, jsonLink, imageLink, newMetadata, metadataIDDict, assetIDArray, hashArray, finalSwappedTraits, updateClaimed ) => {
    var data = JSON.stringify({
      action: "confirmCustomization",
      identifyingHash: identifyingHash,
      transactionID: signature,
      attributeDict: newMetadata,
      attributeIDDict: metadataIDDict,
      metadataLink: jsonLink,
      imageLink: imageLink,
      hash: selectedNFT,
      assetIDArray: assetIDArray,
      hashArray: hashArray,
      userWallet: publicKey,
      swappedTraits: finalSwappedTraits,
      updateClaimed: updateClaimed
    });
    // console.log(data)
    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/astralmint",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let returnResponse = axios(config)
    .then(function (response) {
        // console.log(response)
        return true;
      })
    .catch(function (error) {
        return false;
      });

    return returnResponse;
  };

  // post purchase we reload the traits so that the quantities are updated
  const resetTraitShop = () => {
    setPopup(false)
    setFetchedWalletNFTs(false)
    setFetchedProjectNFTs(false)
    setFilteredProjectNFTs(false)
    setFilteredUserTraits(false)
    setSelectedNFT()
    setChoseTrait(false)
    setShowClaimTraits(false)
    setAllClaimTraits()
    setSwapTraitsArray([])

  };

  // remove the popup from the screen and reset the trait shop
  const resetPopup = () => {
    setPopup(false);
    setPopupState("");
    resetTraitShop();
  };

  // determines the type of popup that appears - toggled between pending, successful, and failed transaction
  const renderPopup = () => {
    if (popupState === "ineligible") {
      return (
        <div class="fixed inset-0 flex flex-rows items-start justify-center z-50">
          <img className="flex relative w-96" src={PopupIneligible}/>
        </div>
      );
    }
    else if (popupState === "internalError") {
      return (
        <div class="fixed inset-0 flex flex-rows items-start justify-center z-50">
          <img className="flex relative w-96" src={PopupInternalError}/>
        </div>
      );
    }
    else if (popupState === "noFunds") {
      return (
        <div class="fixed inset-0 flex flex-rows items-start justify-center z-50">
          <img className="flex relative w-96" src={PopupNoFunds}/>
        </div>
      );
    }
    else if (popupState === "transactionError") {
      return (
        <div class="fixed inset-0 flex flex-rows items-start justify-center z-50">
          <img className="flex relative w-96" src={PopupTransactionError}/>
        </div>
      );
    }
    else if (popupState === "walletPrompt") {
      return (
        <div class="fixed inset-0 flex flex-rows items-start justify-center z-50">
          <div className="relative flex flex-rows">
            <img className="absolute bottom-0.25 left-0 z-10 w-11" src={Loader}/>
            <img className="flex relative w-96" src={PopupWalletPrompt}/>
          </div>
        </div>
      );
    }
    else if (popupState === "pauseWait") {
      return (
        <div class="fixed inset-0 flex flex-rows items-start justify-center z-50">
          <div className="relative flex flex-rows">
            <img className="absolute bottom-0.25 left-0 z-10 w-11" src={Loader}/>
            <img className="flex relative w-96" src={PopupPause}/>
          </div>
        </div>
      );
    }
    else if (popupState === "successfulPurchase") {
      return (
        <div class="fixed inset-0 flex flex-rows items-start justify-center z-50">
          <img className="flex relative w-96" src={PopupSuccessfulPurchase}/>
        </div>
      );
    }
  };

  const chooseNFT = (nftHash) => {
    setSelectedNFT(nftHash)
    setSelectedTrait(0)
    setImageArray([])
    setReadyToUpgrade(false)
    setUpgradeError(false)
    setShowNFTPopup(false)
    setChoseTrait(false)

    setChangingBackgroundTrait(false)
    setSendRaceTrait(false)
    setSendFeetTrait(false)
    setSendBottomTrait(false)
    setSendTopTrait(false)
    setSendLegendaryTrait(false)

    let tempImageArray = []
    // console.log(userProjectNFTs[nftHash])
    setRaceTrait({trait_type: "Race", value: userProjectNFTs[nftHash].attributeDict.Race, assetID: userProjectNFTs[nftHash].attributeIDDict.Race, addedReward: userProjectNFTs[nftHash].eligibilityDict.Race.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Race.requiredClass})
    setBackgroundTrait({trait_type: "Background", value: userProjectNFTs[nftHash].attributeDict.Background, assetID: userProjectNFTs[nftHash].attributeIDDict.Background, addedReward: userProjectNFTs[nftHash].eligibilityDict.Background.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Background.requiredClass})
    // setBackgroundIndex(userTraitDict['Background'].indexOf({addedReward: userProjectNFTs[nftHash].eligibilityDict.Race.addedReward, assetID: userProjectNFTs[nftHash].attributeIDDict.Background, category: "Background", requiredClass: userProjectNFTs[nftHash].eligibilityDict.Background.requiredClass, traitName: userProjectNFTs[nftHash].attributeDict.Background, type: "Background", uploadedLink: }))
    tempImageArray.push({trait_type: "Background", value: userProjectNFTs[nftHash].attributeDict.Background, assetID: userProjectNFTs[nftHash].attributeIDDict.Background, addedReward: userProjectNFTs[nftHash].eligibilityDict.Background.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Background.requiredClass})
    tempImageArray.push({trait_type: "Race", value: userProjectNFTs[nftHash].attributeDict.Race, assetID: userProjectNFTs[nftHash].attributeIDDict.Race, addedReward: userProjectNFTs[nftHash].eligibilityDict.Race.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Race.requiredClass})
    userProjectNFTs[nftHash].attributeDict.Race.includes("1") ?
      setCurrentRaceVersion("V1")
      :
      setCurrentRaceVersion("V2")

    if (Object.keys(userProjectNFTs[nftHash].eligibilityDict).includes("Feet")){
      setFeetTrait({trait_type: "Feet", value: userProjectNFTs[nftHash].attributeDict.Feet, assetID: userProjectNFTs[nftHash].attributeIDDict.Feet, addedReward: userProjectNFTs[nftHash].eligibilityDict.Feet.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Feet.requiredClass})
      tempImageArray.push({trait_type: "Feet", value: userProjectNFTs[nftHash].attributeDict.Feet, assetID: userProjectNFTs[nftHash].attributeIDDict.Feet, addedReward: userProjectNFTs[nftHash].eligibilityDict.Feet.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Feet.requiredClass})
    }
    else{
      setFeetTrait({trait_type: "Feet", value: userProjectNFTs[nftHash].attributeDict.Feet, assetID: userProjectNFTs[nftHash].attributeIDDict.Feet})
      tempImageArray.push({trait_type: "Feet", value: userProjectNFTs[nftHash].attributeDict.Feet, assetID: userProjectNFTs[nftHash].attributeIDDict.Feet})
    }

    if (Object.keys(userProjectNFTs[nftHash].eligibilityDict).includes("Bottom")){
      setBottomTrait({trait_type: "Bottom", value: userProjectNFTs[nftHash].attributeDict.Bottom, assetID: userProjectNFTs[nftHash].attributeIDDict.Bottom, addedReward: userProjectNFTs[nftHash].eligibilityDict.Bottom.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Bottom.requiredClass})
      tempImageArray.push({trait_type: "Bottom", value: userProjectNFTs[nftHash].attributeDict.Bottom, assetID: userProjectNFTs[nftHash].attributeIDDict.Bottom})
    }
    else{
      setBottomTrait({trait_type: "Bottom", value: userProjectNFTs[nftHash].attributeDict.Bottom, assetID: userProjectNFTs[nftHash].attributeIDDict.Bottom})
      tempImageArray.push({trait_type: "Bottom", value: userProjectNFTs[nftHash].attributeDict.Bottom, assetID: userProjectNFTs[nftHash].attributeIDDict.Bottom})
    }

    if (Object.keys(userProjectNFTs[nftHash].eligibilityDict).includes("Top")){
      setTopTrait({trait_type: "Top", value: userProjectNFTs[nftHash].attributeDict.Top, assetID: userProjectNFTs[nftHash].attributeIDDict.Top, addedReward: userProjectNFTs[nftHash].eligibilityDict.Top.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Top.requiredClass})
      tempImageArray.push({trait_type: "Top", value: userProjectNFTs[nftHash].attributeDict.Top, assetID: userProjectNFTs[nftHash].attributeIDDict.Top, addedReward: userProjectNFTs[nftHash].eligibilityDict.Top.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Top.requiredClass})
    }
    else{
      setTopTrait({trait_type: "Top", value: userProjectNFTs[nftHash].attributeDict.Top, assetID: userProjectNFTs[nftHash].attributeIDDict.Top})
      tempImageArray.push({trait_type: "Top", value: userProjectNFTs[nftHash].attributeDict.Top, assetID: userProjectNFTs[nftHash].attributeIDDict.Top})
    }

    if (Object.keys(userProjectNFTs[nftHash].eligibilityDict).includes("Legendary")){
      setLegendaryTrait({trait_type: "Legendary", value: userProjectNFTs[nftHash].attributeDict.Legendary, assetID: userProjectNFTs[nftHash].attributeIDDict.Legendary, addedReward: userProjectNFTs[nftHash].eligibilityDict.Legendary.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Legendary.requiredClass})
      tempImageArray.push({trait_type: "Legendary", value: userProjectNFTs[nftHash].attributeDict.Legendary, assetID: userProjectNFTs[nftHash].attributeIDDict.Legendary, addedReward: userProjectNFTs[nftHash].eligibilityDict.Legendary.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Legendary.requiredClass})
    }
    else{
      setLegendaryTrait({trait_type: "Legendary", value: userProjectNFTs[nftHash].attributeDict.Legendary, assetID: userProjectNFTs[nftHash].attributeIDDict.Legendary})
      tempImageArray.push({trait_type: "Legendary", value: userProjectNFTs[nftHash].attributeDict.Legendary, assetID: userProjectNFTs[nftHash].attributeIDDict.Legendary})
    }
    generateImage(true, tempImageArray)

    let eligibleClassesTemp = []
    if (userProjectNFTs[nftHash].attributeDict.Class === "Rookie"){
      eligibleClassesTemp.push("Rookie")
    }
    else if (userProjectNFTs[nftHash].attributeDict.Class === "Soldier"){
      eligibleClassesTemp.push("Rookie")
      eligibleClassesTemp.push("Soldier")
    }
    else if (userProjectNFTs[nftHash].attributeDict.Class === "Elite"){
      eligibleClassesTemp.push("Rookie")
      eligibleClassesTemp.push("Soldier")
      eligibleClassesTemp.push("Elite")
    }
    else if (userProjectNFTs[nftHash].attributeDict.Class === "Destructor"){
      eligibleClassesTemp.push("Rookie")
      eligibleClassesTemp.push("Soldier")
      eligibleClassesTemp.push("Elite")
      eligibleClassesTemp.push("Destructor")
    }
    renderSatusBarType(userProjectNFTs[nftHash].attributeDict.Xyon)
    setEligibleClasses(eligibleClassesTemp)
    setSelectedNFTClass(userProjectNFTs[nftHash].attributeDict.Class)
    setSelectedXyon(userProjectNFTs[nftHash].attributeDict.Xyon)
  }

  const changeTrait = (category, trait) => {
    setChoseTrait(true)

    if (category === "Background" && trait.assetID !== backgroundTrait.assetID){
      setPickingBackground(true)
      setBackgroundTrait({trait_type: "Background", value: trait.traitName, assetID: trait.assetID, addedReward: trait.addedReward, requiredClass: trait.requiredClass})
      setBackgroundIndex(userTraitDict[category].indexOf(trait))
    }
    else if (category === "Race" && trait.assetID !== raceTrait.assetID){
      setSendRaceTrait(true)
      setRaceTrait({trait_type: "Race", value: trait.traitName, assetID: trait.assetID, addedReward: trait.addedReward, requiredClass: trait.requiredClass, hash: trait.hash})
      setRaceIndex(userTraitDict[category].indexOf(trait))
      setSendTopTrait(false)
      if (Object.keys(userProjectNFTs[selectedNFT].eligibilityDict).includes("Top")){
        setTopTrait({trait_type: "Top", value: userProjectNFTs[selectedNFT].attributeDict.Top, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Top, addedReward: userProjectNFTs[selectedNFT].eligibilityDict.Top.addedReward, requiredClass: userProjectNFTs[selectedNFT].eligibilityDict.Top.requiredClass})
      }
      else{
        setTopTrait({trait_type: "Top", value: userProjectNFTs[selectedNFT].attributeDict.Top, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Top})
      }
      setTopIndex(0)

      setSendBottomTrait(false)
      if (Object.keys(userProjectNFTs[selectedNFT].eligibilityDict).includes("Bottom")){
        setBottomTrait({trait_type: "Bottom", value: userProjectNFTs[selectedNFT].attributeDict.Bottom, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Bottom, addedReward: userProjectNFTs[selectedNFT].eligibilityDict.Bottom.addedReward, requiredClass: userProjectNFTs[selectedNFT].eligibilityDict.Bottom.requiredClass})
      }
      else{
        setBottomTrait({trait_type: "Bottom", value: userProjectNFTs[selectedNFT].attributeDict.Bottom, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Bottom})
      }
      setBottomIndex(0)

      setSendFeetTrait(false)
      if (Object.keys(userProjectNFTs[selectedNFT].eligibilityDict).includes("Feet")){
        setFeetTrait({trait_type: "Feet", value: userProjectNFTs[selectedNFT].attributeDict.Feet, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Feet, addedReward: userProjectNFTs[selectedNFT].eligibilityDict.Feet.addedReward, requiredClass: userProjectNFTs[selectedNFT].eligibilityDict.Feet.requiredClass})
      }
      else{
        setFeetTrait({trait_type: "Feet", value: userProjectNFTs[selectedNFT].attributeDict.Feet, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Feet})
      }
      setFeetIndex(0)

      setSendLegendaryTrait(false)
      if (Object.keys(userProjectNFTs[selectedNFT].eligibilityDict).includes("Legendary")){
        setLegendaryTrait({trait_type: "Legendary", value: userProjectNFTs[selectedNFT].attributeDict.Legendary, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Legendary, addedReward: userProjectNFTs[selectedNFT].eligibilityDict.Legendary.addedReward, requiredClass: userProjectNFTs[selectedNFT].eligibilityDict.Legendary.requiredClass})
      }
      else{
        setLegendaryTrait({trait_type: "Legendary", value: userProjectNFTs[selectedNFT].attributeDict.Legendary, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Legendary})
      }
      setLegendaryIndex(0)

    }
    else if (category === "Top" && trait.assetID !== topTrait.assetID){
      if (trait.assetID !== -1){
        setSendTopTrait(true)
      } else {
        setSendTopTrait(false)
      }
      setTopTrait({trait_type: "Top", value: trait.traitName, assetID: trait.assetID, addedReward: trait.addedReward, requiredClass: trait.requiredClass, hash: trait.hash})
      setTopIndex(userTraitDict[category].indexOf(trait))

      setSendRaceTrait(false)
      setRaceTrait({trait_type: "Race", value: userProjectNFTs[selectedNFT].attributeDict.Race, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Race, addedReward: userProjectNFTs[selectedNFT].eligibilityDict.Race.addedReward, requiredClass: userProjectNFTs[selectedNFT].eligibilityDict.Race.requiredClass})
      setRaceIndex(0)

      setSendBottomTrait(false)
      if (Object.keys(userProjectNFTs[selectedNFT].eligibilityDict).includes("Bottom")){
        setBottomTrait({trait_type: "Bottom", value: userProjectNFTs[selectedNFT].attributeDict.Bottom, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Bottom, addedReward: userProjectNFTs[selectedNFT].eligibilityDict.Bottom.addedReward, requiredClass: userProjectNFTs[selectedNFT].eligibilityDict.Bottom.requiredClass})
      }
      else{
        setBottomTrait({trait_type: "Bottom", value: userProjectNFTs[selectedNFT].attributeDict.Bottom, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Bottom})
      }
      setBottomIndex(0)

      setSendFeetTrait(false)
      if (Object.keys(userProjectNFTs[selectedNFT].eligibilityDict).includes("Feet")){
        setFeetTrait({trait_type: "Feet", value: userProjectNFTs[selectedNFT].attributeDict.Feet, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Feet, addedReward: userProjectNFTs[selectedNFT].eligibilityDict.Feet.addedReward, requiredClass: userProjectNFTs[selectedNFT].eligibilityDict.Feet.requiredClass})
      }
      else{
        setFeetTrait({trait_type: "Feet", value: userProjectNFTs[selectedNFT].attributeDict.Feet, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Feet})
      }
      setFeetIndex(0)

      setSendLegendaryTrait(false)
      setLegendaryTrait({trait_type: "Legendary", value: 'None', assetID: -1})
      setLegendaryIndex(0)
    }
    else if (category === "Bottom" && trait.assetID !== bottomTrait.assetID){
      if (trait.assetID !== -1){
        setSendBottomTrait(true)
      } else {
        setSendBottomTrait(false)
      }
      setBottomTrait({trait_type: "Bottom", value: trait.traitName, assetID: trait.assetID, addedReward: trait.addedReward, requiredClass: trait.requiredClass, hash: trait.hash})
      setBottomIndex(userTraitDict[category].indexOf(trait))

      setSendRaceTrait(false)
      setRaceTrait({trait_type: "Race", value: userProjectNFTs[selectedNFT].attributeDict.Race, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Race, addedReward: userProjectNFTs[selectedNFT].eligibilityDict.Race.addedReward, requiredClass: userProjectNFTs[selectedNFT].eligibilityDict.Race.requiredClass})
      setFeetIndex(0)

      setSendTopTrait(false)
      if (Object.keys(userProjectNFTs[selectedNFT].eligibilityDict).includes("Top")){
        setTopTrait({trait_type: "Top", value: userProjectNFTs[selectedNFT].attributeDict.Top, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Top, addedReward: userProjectNFTs[selectedNFT].eligibilityDict.Top.addedReward, requiredClass: userProjectNFTs[selectedNFT].eligibilityDict.Top.requiredClass})
      }
      else{
        setTopTrait({trait_type: "Top", value: userProjectNFTs[selectedNFT].attributeDict.Top, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Top})
      }
      setTopIndex(0)

      setSendFeetTrait(false)
      if (Object.keys(userProjectNFTs[selectedNFT].eligibilityDict).includes("Feet")){
        setFeetTrait({trait_type: "Feet", value: userProjectNFTs[selectedNFT].attributeDict.Feet, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Feet, addedReward: userProjectNFTs[selectedNFT].eligibilityDict.Feet.addedReward, requiredClass: userProjectNFTs[selectedNFT].eligibilityDict.Feet.requiredClass})
      }
      else{
        setFeetTrait({trait_type: "Feet", value: userProjectNFTs[selectedNFT].attributeDict.Feet, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Feet})
      }
      setFeetIndex(0)

      setSendLegendaryTrait(false)
      setLegendaryTrait({trait_type: "Legendary", value: 'None', assetID: -1})
      setLegendaryIndex(0)
    }
    else if (category === "Feet" && trait.assetID !== feetTrait.assetID){
      if (trait.assetID !== -1){
        setSendFeetTrait(true)
      } else {
        setSendFeetTrait(false)
      }
      setFeetTrait({trait_type: "Feet", value: trait.traitName, assetID: trait.assetID, addedReward: trait.addedReward, requiredClass: trait.requiredClass, hash: trait.hash})
      setFeetIndex(userTraitDict[category].indexOf(trait))

      setSendRaceTrait(false)
      setRaceTrait({trait_type: "Race", value: userProjectNFTs[selectedNFT].attributeDict.Race, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Race, addedReward: userProjectNFTs[selectedNFT].eligibilityDict.Race.addedReward, requiredClass: userProjectNFTs[selectedNFT].eligibilityDict.Race.requiredClass})
      setRaceIndex(0)

      setSendTopTrait(false)
      if (Object.keys(userProjectNFTs[selectedNFT].eligibilityDict).includes("Top")){
        setTopTrait({trait_type: "Top", value: userProjectNFTs[selectedNFT].attributeDict.Top, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Top, addedReward: userProjectNFTs[selectedNFT].eligibilityDict.Top.addedReward, requiredClass: userProjectNFTs[selectedNFT].eligibilityDict.Top.requiredClass})
      }
      else{
        setTopTrait({trait_type: "Top", value: userProjectNFTs[selectedNFT].attributeDict.Top, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Top})
      }
      setTopIndex(0)

      setSendBottomTrait(false)
      if (Object.keys(userProjectNFTs[selectedNFT].eligibilityDict).includes("Bottom")){
        setBottomTrait({trait_type: "Bottom", value: userProjectNFTs[selectedNFT].attributeDict.Bottom, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Bottom, addedReward: userProjectNFTs[selectedNFT].eligibilityDict.Bottom.addedReward, requiredClass: userProjectNFTs[selectedNFT].eligibilityDict.Bottom.requiredClass})
      }
      else{
        setBottomTrait({trait_type: "Bottom", value: userProjectNFTs[selectedNFT].attributeDict.Bottom, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Bottom})
      }
      setBottomIndex(0)

      setSendLegendaryTrait(false)
      setLegendaryTrait({trait_type: "Legendary", value: 'None', assetID: -1})
      setLegendaryIndex(0)
    }
    else if (category === "Legendary" && trait.assetID !== legendaryTrait.assetID){
      if (trait.assetID !== -1){
        setSendLegendaryTrait(true)
      } else {
        setSendLegendaryTrait(false)
      }
      setLegendaryTrait({trait_type: "Legendary", value: trait.traitName, assetID: trait.assetID, addedReward: trait.addedReward, requiredClass: trait.requiredClass, hash: trait.hash})
      setLegendaryIndex(userTraitDict[category].indexOf(trait))

      setSendRaceTrait(false)
      setRaceTrait({trait_type: "Race", value: userProjectNFTs[selectedNFT].attributeDict.Race, assetID: userProjectNFTs[selectedNFT].attributeIDDict.Race, addedReward: userProjectNFTs[selectedNFT].eligibilityDict.Race.addedReward, requiredClass: userProjectNFTs[selectedNFT].eligibilityDict.Race.requiredClass})
      setRaceIndex(0)

      setTopTrait({trait_type: "Top", value: 'None', assetID: -1})
      setTopIndex(0)

      setSendBottomTrait(false)
      setBottomTrait({trait_type: "Bottom", value: 'None', assetID: -1})
      setBottomIndex(0)

      setSendFeetTrait(false)
      setFeetTrait({trait_type: "Feet", value: 'None', assetID: -1})
      setFeetIndex(0)
    }
  }

  const renderNFTs = () => (
      <div style={{ maxHeight: "calc(100vh - 200px)",  minHeight: "calc(100vh - 200px)"}} className="hide-scrollbar w-[80%]  text-center overflow-y-scroll">
          { Object.keys(userProjectNFTs)?.length ?
              <div className="grid grid-cols-4 2xl:gap-12 gap-16 pb-5">
                  { Object.keys(userProjectNFTs).map(nftHash => {
                      return (
                          <div onClick={() => chooseNFT(nftHash)} class="w-full pb-[100%] relative   cursor-pointer  ">
                              <img
                                src={`${userProjectNFTs[nftHash].imageLink}?${new Date().getTime()}`}
                                className="absolute w-full h-full object-contain rounded-3xl top-0 left-0 transition-all  hover:shadow-lg hover:scale-95"
                              />
                          </div>
                      );
                  })}
               </div>:
                (
                    <div className="w-full h-full justify-center items-center text-center xl:w-1/2 mx-auto text-2xl font-title-regular text-white">
                        There is no NFT in your wallet from this particular collection. Please connect a new wallet and
                        try again or switch the collection.
                    </div>
                )
            }
      </div>
  )

  const renderImage = () => {
    if (selectedNFT) {
      if (upgradeError) {
        return(
          <div className="flex justify-center mx-auto mb-5  relative rounded-md   w-full">
              <div
                style={{ borderColor: theme?.color1 }}
                className="w-full pb-[calc(100%-4px)] relative rounded-md border-2 border-primary-red overflow-hidden"
              >
                  <img className="absolute  top-0 left-0  w-full h-full object-contain" src={NotAllowed} />
              </div>
          </div>
        )
      }

      // If the combination was allowed and the NFT is set to upload
      else if (imageArray.length) {
        return (

          <div>
            {
              pickingBackground
              ? imageArray.map(link => (
                  <>
                  <img
                    className="w-full h-full object-cover absolute top-0 left-0 rounded-2xl"
                    src={link}
                    alt="Selected NFT"
                  />
                  <button
                    className="absolute top-0 right-0 text-white p-2 rounded-full z-10"
                    onClick={() => {setPickingBackground(false)}}
                  >
                    X
                  </button>
                  </>
                ))
              : imageArray
                  .filter(link => !(link.includes('Background')))
                  .map(link => (
                    <img
                      className="w-full h-full object-cover absolute top-0 left-0"
                      src={link}
                      alt="Selected NFT"
                    />
                  ))}
          </div>
        );
      }
    }
  };

  const renderClass = () => {
    if (selectedNFTClass === "Rookie"){
      return (
        <img class="w-[28%] 2xl:w-[32%]" src={RookieBadge}></img>
      )
    }
    else if(selectedNFTClass === "Soldier"){
      return (
        <img  class="w-[28%] 2xl:w-[32%]" src={SoldierBadge}></img>
      )
    }
    else if(selectedNFTClass === "Elite"){
      return (
        <img class="w-[28%] 2xl:w-[32%]" src={EliteBadge}></img>
      )
    }
    else if(selectedNFTClass === "Destructor"){
      return (
        <img class="w-[28%] 2xl:w-[32%]" src={DestructorBadge}></img>
      )
    }
  }

  const renderMoreInfo = () => {
    // <img src={PopupInfo}/>
    return (
      <>
      <img class="object-cover w-full h-full" src={PopupInfo}/>
      <div className = "absolute flex-col top-2 left-3 w-[95%] h-full">
        <ul className="w-[95%] list-none text-white font-title-bold font-medium text-[10px] 2xl:text-[12px] 4xl:text-[16px] mb-4">
          <li className="flex items-start">
            <span className="text-more-info-header mr-2">&#10148;</span>
            <span>Equip your purchased gear by selecting the purchased items and pressing "CUSTOMIZE".</span>
          </li>
          <li className="flex items-start">
            <span className="text-more-info-header mr-2">&#10148;</span>
            <span>Once the item is equipped, it will earn you the associated amount of XYON.</span>
          </li>
          <li className="flex items-start">
            <span className="text-more-info-header mr-2">&#10148;</span>
            <span>XYON earned remains with your Astral, even if you unequip your current gear.</span>
          </li>
          <li className="flex items-start">
            <span className="text-more-info-header mr-2">&#10148;</span>
            <span>Earn more XYON to get to a higher class and unlock the ability to purchase higher tier gear.</span>
          </li>
          <li className="flex items-start">
            <span className="text-more-info-header mr-2">&#10148;</span>
            <span>You can only change one piece of gear at a time.</span>
          </li>
        </ul>
        <h1 className = "border-b-2 border-white text-astral-class font-title-bold font-medium text-xs 2xl:text-xl 4xl:text-4xl uppercase mb-2"> Class Descriptions: </h1>
        <p className = "text-white font-title-bold font-medium text-[8px] 2xl:text-[10px] 4xl:text-[16px] mb-1">
          <span className="text-more-info-header font-title-bold font-medium text-[11px] 2xl:text-[12px] 4xl:text-[16px]">ROOKIE:</span>
          <br></br>
          The fresh Grunt. Yet to prove themselves among the Astral Elite… The Freelancer is just stepping in to their boots and looking to get their ears wet.
        </p>
        <p className="text-astral-class font-title-bold font-medium text-[8px] 2xl:text-[10px] 4xl:text-[16px] mb-2">Required XYON: 0</p>
        <p className = "text-white font-title-bold font-medium text-[8px] 2xl:text-[10px] 4xl:text-[16px] mb-1">
          <span className="text-more-info-header font-title-bold font-medium text-[11px] 2xl:text-[12px] 4xl:text-[16px]">SOLDIER:</span>
          <br></br>
          This Astral has managed to find the basic gear to call themselves a SOLDIER.  At this rank the Astral can start expanding the quality of their gear to the point of being taken a little more seriously… Get noticed.
        </p>
        <p className="text-astral-class font-title-bold font-medium text-[8px] 2xl:text-[10px] 4xl:text-[16px] mb-2">Required XYON to unlock: 500</p>
        <p className = "text-white font-title-bold font-medium text-[8px] 2xl:text-[10px] 4xl:text-[16px] mb-1">
          <span className="text-more-info-header font-title-bold font-medium text-[11px] 2xl:text-[12px] 4xl:text-[16px]">ELITE:</span>
          <br></br>
          This Astral has managed to join the ranks of the ELITE.  Well equipped to be deemed respectable, and above the average Astral…  They have access to things that the fresh meat Astrals strive daily to achieve.
        </p>
        <p className="text-astral-class font-title-bold font-medium text-[8px] 2xl:text-[10px] 4xl:text-[16px] mb-2">Required XYON to unlock: 5000</p>
        <p className = "text-white font-title-bold font-medium text-[8px] 2xl:text-[10px] 4xl:text-[16px] mb-1">
          <span className="text-more-info-header font-title-bold font-medium text-[11px] 2xl:text-[12px] 4xl:text-[16px]">DESTRUCTOR:</span>
          <br></br>
          This Astral has Ascended above the Elite…  They are ready for gear worthy of an Astral God…  The best of the best get access to one of a kind Legendary pieces… that are in minimal supply… Only the lucky few that reach Destructor will have the opportunity to collect armor that lesser Astral warriors can only dream of obtaining…
        </p>
        <p className="text-astral-class font-title-bold font-medium text-[8px] 2xl:text-[10px] 4xl:text-[16px] mb-2">Required XYON to unlock: 10000</p>
      </div>
      </>

    )
  }

  const renderSatusBarType = (xyon) => {
    if (xyon === 0){
      setPercentageStatus(0)
    }
    else if (xyon <= 500){
      let calculatePercentage = (xyon / 500) * 100;
      setPercentageStatus(calculatePercentage)
    }
    else if (xyon <= 5000){
      let calculatePercentage = (xyon / 5000) * 100;
      setPercentageStatus(calculatePercentage)
    }
    else if (xyon <= 10000){
      let calculatePercentage = (xyon / 10000) * 100;
      setPercentageStatus(calculatePercentage)
    }
    else {
      setPercentageStatus(100)
    }
  }

  const changeRaceVersion = (raceVersion) => {
    let newRaceName
    if (raceVersion === "V1"){
      newRaceName = raceTrait.value.replace("2", "1")
    }
    else{
      newRaceName = raceTrait.value.replace("1", "2")
    }

    let traitTemp = assetDict.Race[raceVersion].find(trait => trait.traitName === newRaceName)
    setRaceTrait({trait_type: "Race", value: traitTemp.traitName, assetID: traitTemp.assetID, addedReward: traitTemp.addedReward, requiredClass: traitTemp.requiredClass})
    setCurrentRaceVersion(raceVersion)
  }

  return (
    <>
        { publicKey ?
              selectedNFT ?
                  <div className="grid grid-cols-3 min-w-[1500px] min-h-[800px] 2xl:min-h-[900px] 4xl:min-w-[2560px] h-screen -mt-20">
                      {
                        pickingBackground ?
                        <div className="fixed inset-0 bg-black bg-opacity-50 z-10"></div>
                        :
                        ""
                      }
                      {
                        popup ?
                        <div className="fixed inset-0 bg-black bg-opacity-50 z-30"></div>
                        :
                        ""
                      }
                      <div className="relative z-0 mt-8">
                        <div className="flex relative left-12 gap-4 mb-5">
                          {renderClass()}
                          <div className = "flex flex-col justify-center items-center px-6">
                            <div className = "flex flex-col justify-center text-white font-title-bold uppercase bg-black bg-opacity-60 rounded-xl px-6">
                              <div className = "flex gap-6 justify-center text-[200%] 2xl:text-[250%] 4xl:text-[350%] font-gseven items-center">
                                {selectedNFTXyon}
                                <img className="object-contain h-[40%]" src={XyonsImage}/>
                              </div>
                              {
                                percentageStatus === 0 ?
                                <img className="object-contain w-[90%]" src={StatusBarZero}/>
                                :
                                <>
                                {
                                  percentageStatus <= 25 ?
                                  <img className="object-contain w-[90%]" src={StatusBarTwentyFive}/>
                                  :
                                  <>
                                  {
                                    percentageStatus <= 50 ?
                                    <img className="object-contain w-[90%]" src={StatusBarFifty}/>
                                    :
                                    <>
                                    {
                                      percentageStatus <= 75 ?
                                      <img className="object-contain w-[90%]" src={StatusBarSeventyFive}/>
                                      :
                                      <>
                                      {
                                        percentageStatus === 100 ?
                                        <img className="object-contain w-[90%]" src={StatusBar}/>
                                        :
                                        <img className="object-contain w-[90%]" src={StatusBarSeventyFive}/>
                                      }
                                      </>
                                    }
                                    </>
                                  }
                                  </>
                                }
                                </>
                              }
                              <div className="font-elasis text-astral-class text-[180%] 2xl:text-[220%] 4xl:text-[330%]">{selectedNFTClass}</div>
                            </div>
                          </div>
                        </div>
                        <div className="relative h-[65%] w-[90%] left-16 text-white">
                          {renderMoreInfo()}
                        </div>
                        <div className="">
                          <img
                            className="fixed left-0 bottom-0 hover:cursor-pointer bazaarButton"
                            src={BazaarBtn}
                            alt="Logo"
                            onClick={() => props.setPage("astralTraits")}
                          />
                        </div>
                      </div>
                      <div class="relative flex justify-center items-end z-10">
                          {
                            popup ?
                            <>
                            {renderPopup()}
                            </>
                            :
                            ""
                          }
                          { renderImage() }
                      </div>
                      <div className="relative mt-20 z-20">
                          <img ref={imgRef} className="absolute top-0 right-0 object-fit w-full 2xl:max-h-[820px] 4xl:max-h-full" src={CustomizePanelBtn} onLoad={handleResize}/>
                          <div style={{ width: dimensions.width, height: dimensions.height }} className="absolute top-9 right-4 text-white font-title-bold uppercase flex flex-col justify-start items-start">
                            {
                            Object.keys(assetDict).length && Object.keys(assetDict)
                            .filter(category => category === 'Background')
                            .map(category => {
                              return (
                                <div className="h-[15%] w-full flex flex-col justify-center items-center bg-gradient-to-b from-light-blue-transparent to-light-blue-ultra-transparent rounded-t-lg hover:from-blue-transparent hover:to-blue-ultra-transparent">
                                  <div className="flex mb-2 gap-2 items-center justify-center">
                                    {
                                      backgroundIndex > 0 ?
                                      <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][backgroundIndex-1]) : "")}/>
                                      :
                                      <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][userTraitDict[category].length-1]) : "")}/>
                                    }
                                    <div className="font-title-bold font-medium text-center min-w-[250px] text-xl 2xl:text-3xl z-20">
                                        {category}
                                    </div>
                                    {
                                      backgroundIndex >= 0 && userTraitDict[category] && backgroundIndex < userTraitDict[category].length-1 ?
                                      <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][backgroundIndex+1]) : "")}/>
                                      :
                                      <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][0]) : "")}/>
                                    }
                                  </div>
                                  <div className="flex flex-wrap items-center justify-center w-[60%]">
                                    {
                                    userTraitDict[category] ?
                                    userTraitDict[category].map(trait => {
                                      return (
                                        <>
                                        {
                                          backgroundTrait.assetID === trait.assetID ?
                                          <div onClick={() => changeTrait(category, trait)} className="w-4 h-4 bg-astral-box-picked border-2 border-astral-box-border-picked rounded-sm mr-2 mb-2 z-20">
                                          </div>
                                          :
                                          <div onClick={() => changeTrait(category, trait)} className="hover:cursor-pointer hover:bg-astral-box-picked w-4 h-4 bg-astral-box border border-astral-box-border rounded-sm mr-2 mb-2 z-20">
                                          </div>
                                        }
                                        </>
                                      )
                                    }
                                    )
                                    :
                                    <div className="font-title-regular text-sm max-w-[250px] text-center">
                                        No '{category}' traits available in your wallet.
                                    </div>
                                  }
                                  </div>
                                </div>
                              )
                            })
                            }
                            <div className = "h-[55%] w-full gradient-border bg-gradient-to-t from-light-blue-transparent to-light-blue-ultra-transparent rounded-b-lg hover:from-blue-transparent hover:to-blue-ultra-transparent">
                              { Object.keys(assetDict).length && Object.keys(assetDict)
                              .filter(category => category !== 'Background' && category !== 'Legendary')
                              .map(category => {
                                return (
                                  <div className="h-[25%] flex flex-col justify-center items-center bg-gradient-to-t from-light-white-transparent to-light-white-ultra-transparent">
                                    <div className="flex mb-2 gap-2 items-center justify-center">
                                        {
                                          category === 'Race'?
                                          <>
                                          {
                                            raceIndex > 0 ?
                                            <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][raceIndex-1]) : "")}/>
                                            :
                                            <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][userTraitDict[category].length-1]) : "")}/>
                                          }
                                          </>
                                          :
                                          <>
                                          {
                                            category === 'Top' ?
                                            <>
                                            {
                                              topIndex > 0 ?
                                              <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][topIndex-1]) : "")}/>
                                              :
                                              <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][userTraitDict[category].length-1]) : "")}/>
                                            }
                                            </>
                                            :
                                            <>
                                            {
                                              category === 'Bottom' ?
                                              <>
                                              {
                                                bottomIndex > 0 ?
                                                <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][bottomIndex-1]) : "")}/>
                                                :
                                                <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][userTraitDict[category].length-1]) : "")}/>
                                              }
                                              </>
                                              :
                                              <>
                                              {
                                                category === 'Feet' ?
                                                <>
                                                {
                                                  feetIndex > 0 ?
                                                  <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][feetIndex-1]) : "")}/>
                                                  :
                                                  <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][userTraitDict[category].length-1]) : "")}/>
                                                }
                                                </>
                                                :
                                                <>
                                                {
                                                  category === 'Legendary' ?
                                                  <>
                                                  {
                                                    legendaryIndex > 0 ?
                                                    <img className="hover:cursor-pointer w-[36px] h-[36px] legleftArrow z-20" src={LegLeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][legendaryIndex-1]) : "")}/>
                                                    :
                                                    <img className="hover:cursor-pointer w-[36px] h-[36px] legleftArrow z-20" src={LegLeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][userTraitDict[category].length-1]) : "")}/>
                                                  }
                                                  </>
                                                  :
                                                  ""
                                                }
                                                </>
                                              }
                                              </>
                                            }
                                            </>
                                          }
                                          </>
                                        }
                                        <div className="text-center font-title-bold font-medium min-w-[250px] text-xl 2xl:text-3xl">
                                            {category}
                                        </div>
                                        {
                                          category === 'Race' ?
                                          <>
                                          {
                                            raceIndex >= 0 && userTraitDict[category] && raceIndex < userTraitDict[category].length-1 ?
                                            <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][raceIndex+1]) : "")}/>
                                            :
                                            <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][0]) : "")}/>
                                          }
                                          </>
                                          :
                                          <>
                                          {
                                            category === 'Top' && userTraitDict[category] ?
                                            <>
                                            {
                                              topIndex >= 0 && topIndex < userTraitDict[category].length-1 ?
                                              <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][topIndex+1]) : "")}/>
                                              :
                                              <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][0]) : "")}/>
                                            }
                                            </>
                                            :
                                            <>
                                            {
                                              category === 'Bottom' && userTraitDict[category] ?
                                              <>
                                              {
                                                bottomIndex >= 0 && bottomIndex < userTraitDict[category].length-1 ?
                                                <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][bottomIndex+1]) : "")}/>
                                                :
                                                <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][0]) : "")}/>
                                              }
                                              </>
                                              :
                                              <>
                                              {
                                                category === 'Feet' && userTraitDict[category] ?
                                                <>
                                                {
                                                  feetIndex >= 0 && feetIndex < userTraitDict[category].length-1 ?
                                                  <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][feetIndex+1]) : "")}/>
                                                  :
                                                  <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][0]) : "")}/>
                                                }
                                                </>
                                                :
                                                <>
                                                {
                                                  category === 'Legendary' && userTraitDict[category] ?
                                                  <>
                                                  {
                                                    legendaryIndex >= 0 && legendaryIndex < userTraitDict[category].length-1 ?
                                                    <img className="hover:cursor-pointer w-[36px] h-[36px] legRightArrow z-20" src={LegRightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][legendaryIndex+1]) : "")}/>
                                                    :
                                                    <img className="hover:cursor-pointer w-[36px] h-[36px] legRightArrow z-20" src={LegRightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][0]) : "")}/>
                                                  }
                                                  </>
                                                  :
                                                  ""
                                                }
                                                </>
                                              }
                                              </>
                                            }
                                            </>
                                          }
                                          </>
                                        }
                                    </div>
                                    <div className="flex flex-wrap items-center justify-center">
                                      {
                                        userTraitDict[category] ?
                                        userTraitDict[category].map(trait => {
                                          var traitPicked = ''
                                          if (category === 'Race'){
                                            traitPicked = raceTrait.assetID
                                          }
                                          else if (category === 'Top'){
                                            traitPicked = topTrait.assetID
                                          }
                                          else if (category === 'Bottom'){
                                            traitPicked = bottomTrait.assetID
                                          }
                                          else if (category === 'Feet'){
                                            traitPicked = feetTrait.assetID
                                          }
                                          else if (category === 'Legendary'){
                                            traitPicked = legendaryTrait.assetID
                                          }
                                          return (
                                            <>
                                            {
                                              category === 'Legendary' ?
                                              <>
                                              {
                                                traitPicked === trait.assetID ?
                                                <div onClick={() => changeTrait(category, trait)} className="w-4 h-4 bg-leg-astral-box-picked border-2 border-leg-astral-box-border-picked rounded-sm mr-2 mb-4">
                                                </div>
                                                :
                                                <div onClick={() => changeTrait(category, trait)} className="hover:cursor-pointer hover:bg-leg-astral-box-picked w-4 h-4 bg-leg-astral-box border border-leg-astral-box-border rounded-sm mr-2 mb-4">
                                                </div>
                                              }
                                              </>
                                              :
                                              <>
                                              {
                                                traitPicked === trait.assetID ?
                                                <div onClick={() => changeTrait(category, trait)} className="w-4 h-4 bg-astral-box-picked border-2 border-astral-box-border-picked rounded-sm mr-2 mb-2 z-20">
                                                </div>
                                                :
                                                <div onClick={() => changeTrait(category, trait)} className="hover:cursor-pointer hover:bg-astral-box-picked w-4 h-4 bg-astral-box border border-astral-box-border rounded-sm mr-2 mb-2 z-20">
                                                </div>
                                              }
                                              </>
                                            }
                                            </>
                                          )
                                        }
                                        )
                                        :
                                        <div className="font-title-regular text-sm max-w-[250px] text-center">
                                            No '{category}' traits available in your wallet.
                                        </div>
                                      }
                                    </div>
                                  </div>
                                )
                            })}
                            </div>
                            <div class="h-[1%] w-full"/>
                            { Object.keys(assetDict).length && Object.keys(assetDict)
                              .filter(category => category === 'Legendary')
                              .map(category => {
                                return (
                                  <div className="h-[15%] w-full flex flex-col justify-center items-center gradient-yellow bg-gradient-to-b from-light-yellow-transparent to-light-yellow-ultra-transparent rounded-t-lg hover:from-yellow-transparent hover:to-yellow-ultra-transparent">
                                    <div className="flex mb-2 gap-2 items-center justify-center">
                                        {
                                          category === 'Race'?
                                          <>
                                          {
                                            raceIndex > 0 ?
                                            <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][raceIndex-1]) : "")}/>
                                            :
                                            <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][userTraitDict[category].length-1]) : "")}/>
                                          }
                                          </>
                                          :
                                          <>
                                          {
                                            category === 'Top' ?
                                            <>
                                            {
                                              topIndex > 0 ?
                                              <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][topIndex-1]) : "")}/>
                                              :
                                              <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][userTraitDict[category].length-1]) : "")}/>
                                            }
                                            </>
                                            :
                                            <>
                                            {
                                              category === 'Bottom' ?
                                              <>
                                              {
                                                bottomIndex > 0 ?
                                                <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][bottomIndex-1]) : "")}/>
                                                :
                                                <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][userTraitDict[category].length-1]) : "")}/>
                                              }
                                              </>
                                              :
                                              <>
                                              {
                                                category === 'Feet' ?
                                                <>
                                                {
                                                  feetIndex > 0 ?
                                                  <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][feetIndex-1]) : "")}/>
                                                  :
                                                  <img className="hover:cursor-pointer w-[36px] h-[36px] leftArrow z-20" src={LeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][userTraitDict[category].length-1]) : "")}/>
                                                }
                                                </>
                                                :
                                                <>
                                                {
                                                  category === 'Legendary' ?
                                                  <>
                                                  {
                                                    legendaryIndex > 0 ?
                                                    <img className="hover:cursor-pointer w-[36px] h-[36px] legLeftArrow z-20" src={LegLeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][legendaryIndex-1]) : "")}/>
                                                    :
                                                    <img className="hover:cursor-pointer w-[36px] h-[36px] legLeftArrow z-20" src={LegLeftArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][userTraitDict[category].length-1]) : "")}/>
                                                  }
                                                  </>
                                                  :
                                                  ""
                                                }
                                                </>
                                              }
                                              </>
                                            }
                                            </>
                                          }
                                          </>
                                        }
                                        <div className="text-center font-title-bold font-medium min-w-[250px] text-xl 2xl:text-3xl">
                                            {category}
                                        </div>
                                        {
                                          category === 'Race' ?
                                          <>
                                          {
                                            raceIndex >= 0 && userTraitDict[category] && raceIndex < userTraitDict[category].length-1 ?
                                            <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][raceIndex+1]) : "")}/>
                                            :
                                            <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][0]) : "")}/>
                                          }
                                          </>
                                          :
                                          <>
                                          {
                                            category === 'Top' && userTraitDict[category] ?
                                            <>
                                            {
                                              topIndex >= 0 && topIndex < userTraitDict[category].length-1 ?
                                              <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][topIndex+1]) : "")}/>
                                              :
                                              <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][0]) : "")}/>
                                            }
                                            </>
                                            :
                                            <>
                                            {
                                              category === 'Bottom' && userTraitDict[category] ?
                                              <>
                                              {
                                                bottomIndex >= 0 && bottomIndex < userTraitDict[category].length-1 ?
                                                <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][bottomIndex+1]) : "")}/>
                                                :
                                                <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][0]) : "")}/>
                                              }
                                              </>
                                              :
                                              <>
                                              {
                                                category === 'Feet' && userTraitDict[category] ?
                                                <>
                                                {
                                                  feetIndex >= 0 && feetIndex < userTraitDict[category].length-1 ?
                                                  <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][feetIndex+1]) : "")}/>
                                                  :
                                                  <img className="hover:cursor-pointer w-[36px] h-[36px] rightArrow z-20" src={RightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][0]) : "")}/>
                                                }
                                                </>
                                                :
                                                <>
                                                {
                                                  category === 'Legendary' && userTraitDict[category] ?
                                                  <>
                                                  {
                                                    legendaryIndex >= 0 && legendaryIndex < userTraitDict[category].length-1 ?
                                                    <img className="hover:cursor-pointer w-[36px] h-[36px] legRightArrow z-20" src={LegRightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][legendaryIndex+1]) : "")}/>
                                                    :
                                                    <img className="hover:cursor-pointer w-[36px] h-[36px] legRightArrow z-20" src={LegRightArrowInactive} onClick={() => (userTraitDict[category] ? changeTrait(category, userTraitDict[category][0]) : "")}/>
                                                  }
                                                  </>
                                                  :
                                                  ""
                                                }
                                                </>
                                              }
                                              </>
                                            }
                                            </>
                                          }
                                          </>
                                        }
                                    </div>
                                    <div className="flex flex-wrap items-center justify-center">
                                      {
                                        userTraitDict[category] ?
                                        userTraitDict[category].map(trait => {
                                          var traitPicked = ''
                                          if (category === 'Race'){
                                            traitPicked = raceTrait.assetID
                                          }
                                          else if (category === 'Top'){
                                            traitPicked = topTrait.assetID
                                          }
                                          else if (category === 'Bottom'){
                                            traitPicked = bottomTrait.assetID
                                          }
                                          else if (category === 'Feet'){
                                            traitPicked = feetTrait.assetID
                                          }
                                          else if (category === 'Legendary'){
                                            traitPicked = legendaryTrait.assetID
                                          }
                                          return (
                                            <>
                                            {
                                              category === 'Legendary' ?
                                              <>
                                              {
                                                traitPicked === trait.assetID ?
                                                <div onClick={() => changeTrait(category, trait)} className="w-4 h-4 bg-leg-astral-box-picked border-2 border-light-yellow-border rounded-sm mr-2 mb-4">
                                                </div>
                                                :
                                                <div onClick={() => changeTrait(category, trait)} className="hover:cursor-pointer hover:bg-leg-astral-box-picked w-4 h-4 bg-leg-astral-box border border-leg-astral-box-border rounded-sm mr-2 mb-4">
                                                </div>
                                              }
                                              </>
                                              :
                                              <>
                                              {
                                                traitPicked === trait.assetID ?
                                                <div onClick={() => changeTrait(category, trait)} className="w-4 h-4 bg-astral-box-picked border border-astral-box-border mr-2">
                                                </div>
                                                :
                                                <div onClick={() => changeTrait(category, trait)} className="hover:cursor-pointer hover:bg-astral-box-picked w-4 h-4 bg-astral-box border border-astral-box-border mr-2">
                                                </div>
                                              }
                                              </>
                                            }
                                            </>
                                          )
                                        }
                                        )
                                        :
                                        <div className="font-title-regular text-sm max-w-[250px] text-center">
                                            No '{category}' traits available in your wallet.
                                        </div>
                                      }
                                    </div>
                                  </div>
                                )
                            })}
                            <div class="h-[2%] w-full"/>
                            <div className="w-full flex flex-col items-center justify-center gap-4 z-50">
                              <div className="w-full flex items-center justify-center gap-2">
                                <img src={ChooseNFT} disabled={popup} onClick={() => setSelectedNFT(false)} class="w-[40%] h-full hover:cursor-pointer chooseNFT"/>
                                <img src={ResetTraits} disabled={popup} onClick={() => chooseNFT(selectedNFT)} class="w-[40%] h-full hover:cursor-pointer resetTraits"/>
                              </div>
                              <img className="hover:cursor-pointer customizerButton object-cover w-[40%] h-auto" src={CustomizeBtn} disabled={popup} onClick = {() => customize()} />

                            </div>
                          </div>
                      </div>
                  </div>
                  :
                  <div className="flex flex-col w-full h-screen -mt-20 overflow-hidden gap-5 items-center">
                      <p className="text-[2rem] leading-none text-center text-white uppercase max-w-2xl font-title-bold mt-8">SELECT AN NFT TO GET STARTED</p>
                      {renderNFTs()}
                      <div className="flex items-end w-full h-full overflow-hidden z-20 ">
                        <img
                          className="absolute left-0 bottom-0 hover:cursor-pointer bazaarButton"
                          src={BazaarBtn}
                          alt="Logo"
                          onClick={() => props.setPage("astralTraits")}
                        />
                      </div>
                  </div>
              :
              <div className="flex flex-grow justify-center">
                  <p className="text-[2rem] leading-none text-center text-white uppercase max-w-2xl font-title-bold" style={{ minHeight: "calc(100vh - 200px)" }}>
                      Please Connect Your wallet to get started
                  </p>
              </div>
        }
    </>
  );
};

export default CustomizeAstral;
