import React, { useState, useEffect, useCallback, useRef } from "react";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import * as web3 from "@solana/web3.js";
import axios from "axios";
import Loader from "../img/loading.gif";
import Failed from "../img/failedtransaction.png";
import V1ButtonActive from "../img/v1ButtonActive.png";
import V1ButtonInactive from "../img/v1ButtonNonActive.png";
import V2ButtonActive from "../img/v2ButtonActive.png";
import V2ButtonInactive from "../img/v2ButtonNonActive.png";
import BuyNowActive from "../img/buyNowActive.png";
import BuyNowInactive from "../img/buyNowNonActive.png";
import LegBuyNowActive from "../img/legendaryBuyActive.png";
import LegBuyNowInactive from "../img/legendaryBuyNowNotActive.png";
import RightArrowActive from "../img/rightArrowActive.png";
import RightArrowInactive from "../img/rightArrowNotActive.png";
import LeftArrowActive from "../img/leftArrowActive.png";
import LeftArrowInactive from "../img/leftArrowNotActive.png";
import LegRightArrowActive from "../img/legendaryRightArrowActive.png";
import LegLeftArrowActive from "../img/legendaryLeftArrowActive.png";
import LegRightArrowInactive from "../img/legendaryRightNotActive.png";
import LegLeftArrowInactive from "../img/legendaryLeftNotActive.png";
import DestructorBadge from "../img/destructorBadge.png";
import EliteBadge from "../img/eliteBadge.png";
import RookieBadge from "../img/rookieBadge.png";
import SoldierBadge from "../img/soldierBadge.png";
import XyonsImage from "../img/xyonsImage.png";
import StatusBar from "../img/statusBar.png";
import StatusBarZero from "../img/statusBarZero.png";
import StatusBarTwentyFive from "../img/statusBarTwentyFive.png";
import StatusBarFifty from "../img/statusBarFifty.png";
import StatusBarSeventyFive from "../img/statusBarSeventyFive.png";
import MoreInfoInactive from "../img/moreInfoArrowNotActive.png";
import MoreInfoActive from "../img/moreInfoArrowActive.png";
import PopupInfo from "../img/infoPopupBack.png";
import PopupIneligible from '../img/popupIneligible.png'
import PopupNoFunds from '../img/popupNoFunds.png'
import PopupSuccessfulPurchase from '../img/popupSuccessfulPurchase.png'
import PopupTransactionError from '../img/popupTransactionError.png'
import PopupWalletPrompt from '../img/popupWalletPrompt.png'
import PopupInternalError from '../img/popupInternalError.png'
import TraitBG from '../img/trait-bg.png';
import ArmoryBtn from "../img/armoryButtonNonActive.png";
import ChooseNFT from '../img/chooseNFT-off.png';
import ResetTraits from '../img/resetTraits-off.png'
import WindowBlue from '../img/window-blue.png'
import MintBtnOn from '../img/mint-on.png'
import MintBtnOff from '../img/mint-off.png'

import './AstralTrait.css'

import bs58 from "bs58";
import {
  getAccount,
  createTransferCheckedInstruction,
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

import { getParsedNftAccountsByOwner } from "@nfteyez/sol-rayz";

import ProgressBar from "@ramonak/react-progress-bar";
import TraitsHeader from "../components/TraitsHeader";
import TraitItem from "../components/TraitItem";
import { BuyNowBtn, P } from "../components/UI/StyledComponents";
import { useTheme } from "styled-components";
import SemiCricle from "../components/UI/SemiCricle";
import NotAllowed from "../components/UI/not_allowed.png";


const AstralTraits = (props) => {
  const theme = useTheme();

  //internal navigation
  const [page, setPage] = useState("bodyshop");
  const [selectedTraitCategory, setSelectedTraitCategory] = useState("All Traits");
  const [traitCategories, setTraitCategories] = useState([]);

  // nft selection
  const [selectedNFT, setSelectedNFT] = useState(false)
  const [showNFTPopup, setShowNFTPopup] = useState(false);

  const [fetchedWalletNFTs, setFetchedWalletNFTs] = useState(false)
  const [walletNFTs, setWalletNFTs] = useState([])
  const [userProjectNFTs, setUserProjectNFTs] = useState([])

  const [fetchedProjectNFTs, setFetchedProjectNFTs] = useState(false)
  const [projectNFTs, setProjectNFTs] = useState({})

  const [filteredProjectNFTs, setFilteredProjectNFTs] = useState(false)
  const [eligibleClasses, setEligibleClasses] = useState([])

  // image builder
  const [assetDict, setAssetDict] = useState({})
  const [imageArray, setImageArray] = useState([])
  const [currentImageLink, setCurrentImageLink] = useState();

  const [upgradingProjectNFTs, setUpgradingProjectNFTs] = useState([])
  const [upgradeError, setUpgradeError] = useState(false)

  // web3 transactions
  const { connection } = useConnection();
  const { publicKey, sendTransaction, signTransaction } = useWallet();

  // purchasing popups
  const [popup, setPopup] = useState(false);
  const [popupState, setPopupState] = useState("");
  const [sentStatus, setSentStatus] = useState("default");

  // trait details
  const [traitDict, setTraitDict] = useState({})
  const [soldOutTraits, setSoldOutTraits] = useState([])
  const [allTraits, setAllTraits] = useState([]);
  const [selectedTrait, setSelectedTrait] = useState();
  const [currentRaceVersion, setCurrentRaceVersion] = useState("")
  const [gatingHash, setGatingHash] = useState();
  const [hasGated, setHasGated] = useState(false)

  // imageCustomization
  const [backgroundTrait, setBackgroundTrait] = useState()
  const [raceTrait, setRaceTrait] = useState()
  const [topTrait, setTopTrait] = useState()
  const [bottomTrait, setBottomTrait] = useState()
  const [feetTrait, setFeetTrait] = useState()
  const [legendaryTrait, setLegendaryTrait] = useState()
  const [raceIndex, setRaceIndex] = useState()
  const [topIndex, setTopIndex] = useState()
  const [bottomIndex, setBottomIndex] = useState()
  const [feetIndex, setFeetIndex] = useState()
  const [legendaryIndex, setLegendaryIndex] = useState()
  const [traitInfo, setTraitInfo] = useState({})

  const [choseTrait, setChoseTrait] = useState(false)
  const [showMoreInfo, setShowMoreInfo] = useState(false)

  const [sortedData, setSortedData] = useState([])

  // class & rank variables
  const [selectedNFTClass, setSelectedNFTClass] = useState()
  const [selectedNFTXyon, setSelectedXyon] = useState()
  const [percentageStatus, setPercentageStatus] = useState()

  const timeoutRef = useRef(null);

  const glxyToken = 'CJ5U6wPmjxFUyTJpUTS7Rt1UqhTmSVRMvmJ8WD4nndXW'

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const aspectRatio = windowDimensions.width / windowDimensions.height;

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setUserProjectNFTs([])
    setWalletNFTs([])
    setFetchedWalletNFTs(false)
    setFetchedProjectNFTs(false)
    setFilteredProjectNFTs(false)

  }, [publicKey])

  // getting the current project NFTs and the details on out of stock traits
  useEffect(() => {
    if (publicKey && !fetchedWalletNFTs){
      const getNFTs = async () => {
        let myNfts = await getParsedNftAccountsByOwner({
          publicAddress: publicKey.toBase58(),
          connection: connection,
          serialization: true,
        });
        let walletDictTemp = {}
        myNfts.forEach((nft) => {
          walletDictTemp[nft.mint] = nft.data.uri
        });
        setFetchedWalletNFTs(true)
        setWalletNFTs(myNfts)
      };
      getNFTs();
    }

    if (publicKey && !fetchedProjectNFTs){
      var data = JSON.stringify({
        projectID: props.projectID,
        action: "getCurrentNFTs",
        userWallet: publicKey.toBase58()
      });

      var config = {
        method: "post",
        url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/astralmint",
        headers: {
          "x-api-key": process.env.GATEWAY_KEY,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          // console.log(response.data.assetDict['Top'])
          let tempAssetDict = response.data.assetDict
          const newTop = response.data.assetDict['Top'].filter(item => item.traitName !== 'None')
          const newBottom = response.data.assetDict['Bottom'].filter(item => item.traitName !== 'Basic')
          const newFeet = response.data.assetDict['Feet'].filter(item => item.traitName !== 'None')
          const newLegendary = response.data.assetDict['Legendary'].filter(item => item.traitName !== 'None')
          tempAssetDict['Top'] = newTop
          tempAssetDict['Bottom'] = newBottom
          tempAssetDict['Feet'] = newFeet
          tempAssetDict['Legendary'] = newLegendary

          setProjectNFTs(response.data.nfts)
          setAssetDict(tempAssetDict)
          setSoldOutTraits(response.data.soldOutTraits)
          setFetchedProjectNFTs(true)
          setTraitDict(response.data.traitDict)
        })
        .catch(function (error) {
          // // console.log(error);
        });
    }
  }, [fetchedWalletNFTs, fetchedProjectNFTs , publicKey])

  useEffect(() => {
    if (walletNFTs.length && fetchedWalletNFTs && fetchedProjectNFTs && !filteredProjectNFTs){
      const filterUserNFTs = async () => {
        let ownedNFTDictTemp = {}

        await Promise.all(walletNFTs.map(async (nft) => {
          if (Object.keys(projectNFTs).includes(nft.mint)) {
            ownedNFTDictTemp[nft.mint] = {...projectNFTs[nft.mint]}
          }
        }))
        // console.log(ownedNFTDictTemp)
        setUserProjectNFTs(ownedNFTDictTemp)
        setFilteredProjectNFTs(true)
      };
      filterUserNFTs();
    }
  }, [walletNFTs, fetchedProjectNFTs, fetchedWalletNFTs])

  useEffect(() => {
      // console.log(selectedNFT)
      if (selectedNFT){
        generateImage(false, [])
    }
  }, [selectedNFT, raceTrait, backgroundTrait, topTrait, bottomTrait, feetTrait, legendaryTrait]);

  const generateImage = (start, startingAttributeArray) => {

    let newAttributeList = []

    if (start){
      newAttributeList = [...startingAttributeArray]
    }
    else{
      newAttributeList.push(backgroundTrait)
      newAttributeList.push(raceTrait)
      newAttributeList.push(feetTrait)
      newAttributeList.push(bottomTrait)
      newAttributeList.push(topTrait)
      newAttributeList.push(legendaryTrait)
    }

    var data = JSON.stringify({
      "action": "setCustomizerImage2",
      "projectID": props.projectID,
      "selectedTrait": {...selectedTrait, cosmetic: 0},
      "attributes": newAttributeList,
      "selectedNFT": {...userProjectNFTs[selectedNFT], hash: selectedNFT},
      "collectionName": "Base",
      "swap": 0
    });

    var config = {
      method: 'post',
      url: 'https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/astralmint',
      headers: {
        'x-api-key': process.env.GATEWAY_KEY,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
    .then(function (response) {
      // console.log(response)
      if (!response.data.error && !("errorMessage" in response.data)) {
        setImageArray(response.data.imageArray)
      }
      else {
        setUpgradeError(true);
      }
    })
    .catch(function (error) {
      // // console.log(error);
    });
  }

  const checkEligibility = (trait) => {
    let eligible = true
    if (!eligibleClasses.includes(trait.requiredClass)){
      eligible = false
    }

    // if (trait.requiredClass === "Destructor"){
    //   // TODO: need to see if this trait has already been purchased by the NFT
    //   // TODO: need to check if this trait is sold out
    //   // var data = JSON.stringify({
    //   //   traitID: trait.id,
    //   //   action: "getTraitToPurchase",
    //   // });
    //   //
    //   // var config = {
    //   //   method: "post",
    //   //   url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/traitpurchase",
    //   //   headers: {
    //   //     "x-api-key": process.env.GATEWAY_KEY,
    //   //     "Content-Type": "application/json",
    //   //   },
    //   //   data: data,
    //   // };
    //   //
    //   // let soldOut = axios(config)
    //   //   .then(function (response) {
    //   //     if (response.data.soldOut) {
    //   //       setPopupState("soldOutTrait");
    //   //       setPopup(true);
    //   //       return response.data.soldOut;
    //   //     } else {
    //   //       return response.data.soldOut;
    //   //     }
    //   //   })
    //   //   .catch(function (error) {
    //   //     setPopup(true);
    //   //     setPopupState("fetchingError");
    //   //     return { soldOut: 1 };
    //   //   });
    //   eligible = false
    // }

    return eligible
  }

  const purchaseTrait = useCallback(
    async (category) => {
      try {
        if (!publicKey) throw new WalletNotConnectedError();

        let traitToPurchase;
        if (category === "Race"){
          traitToPurchase = raceTrait
        }
        else if (category === "Background"){
          traitToPurchase = backgroundTrait
        }
        else if (category === "Top"){
          traitToPurchase = topTrait
        }
        else if (category === "Bottom"){
          traitToPurchase = bottomTrait
        }
        else if (category === "Feet"){
          traitToPurchase = feetTrait
        }
        else if (category === "Legendary"){
          traitToPurchase = legendaryTrait
        }
        if (!Object.keys(traitDict).map(Number).includes(traitToPurchase.assetID)){
          setPopup(true);
          setPopupState("internalError");
          timeoutRef.current = setTimeout(setPopup(false), 10000)
        }

        var isEligible = await checkEligibility(traitToPurchase)
        if (isEligible && Object.keys(traitDict).map(Number).includes(traitToPurchase.assetID)) {
          try{
            setSelectedTrait(traitToPurchase);
            setPopup(true);
            setPopupState("walletPrompt");

            // purchased with SPL Token
            const tokenPublicKey = await new web3.PublicKey(glxyToken);

            const traitPublicKey = await new web3.PublicKey(traitDict[traitToPurchase.assetID].hash);
            // this is the shared astral wallet - where traits will be stored and sent to on customization
            const astralWallet = await new web3.PublicKey("HGTqPujVWsf6jRxr8FAEXQWpWrGAD4idxaJ5jxYRK9mB");
            const feeWallet = await new web3.PublicKey(
              "DdFBV8t6xeACpG7R7whMp7HoCd5QtQGgs5NCoct3Bqix"
            );
            // const creatorDestination = await new web3.PublicKey(props.projectCreator);
            const walletKey = web3.Keypair.fromSecretKey(bs58.decode(process.env.ASTRAL_WALLET));

            // this is the token account for the creator wallet for the spl token
            const destinationWalletCoinAccount = await getAssociatedTokenAddress(
              tokenPublicKey,
              astralWallet
            );
            let coin_account;
            try {
              coin_account = await getAccount(connection, destinationWalletCoinAccount);
            }
            catch (error) {
              try {
                // replaced creatorDestination with astralWallet - this is creating the account if the account doesnt already exist (shouldnt hit this)
                const transaction = new web3.Transaction().add(
                  createAssociatedTokenAccountInstruction(
                    publicKey,
                    destinationWalletCoinAccount,
                    astralWallet,
                    tokenPublicKey,
                    TOKEN_PROGRAM_ID,
                    ASSOCIATED_TOKEN_PROGRAM_ID
                  )
                );
                const signature = await sendTransaction(transaction, connection);
                await connection.confirmTransaction(signature, "processed");
              }
              catch (error) {
                // console.log("error 1")
                setPopup(true);
                setPopupState("transactionError");
                timeoutRef.current = setTimeout(cancelPopup, 5000)
                throw error;
              }
            }

            const sourceWalletCoinAccount = await getAssociatedTokenAddress(
              tokenPublicKey,
              publicKey
            );

            // this is the trait account for the purchasing wallet
            const destinationWalletTraitAccount = await getAssociatedTokenAddress(
              traitPublicKey,
              publicKey
            );
            let trait_account;
            try {
              trait_account = await getAccount(connection, destinationWalletTraitAccount);
            }
            catch (error) {
              try {
                // changed the payer to be the user - replaced our wallet with publickey
                const transaction = new web3.Transaction().add(
                  createAssociatedTokenAccountInstruction(
                    astralWallet,
                    destinationWalletTraitAccount,
                    publicKey,
                    traitPublicKey,
                    TOKEN_PROGRAM_ID,
                    ASSOCIATED_TOKEN_PROGRAM_ID
                  )
                );

                const signature = await web3.sendAndConfirmTransaction(connection, transaction, [walletKey,]);
              }
              catch (error) {
                console.log("error 2")
                setPopup(true);
                setPopupState("transactionError");
                timeoutRef.current = setTimeout(cancelPopup, 5000)
                throw error;
              }
            }

            // this is the same call as destinationWalletCoinAccount above
            const sourceWalletTraitAccount = await getAssociatedTokenAddress(
              traitPublicKey,
              astralWallet
            );

            let tokenInfo = await connection.getTokenSupply(tokenPublicKey)
            let decimal = tokenInfo.value.decimals
            let finalDecimal = 10 ** decimal
            // traitDict[traitToPurchase.assetID].price
            var transaction = new web3.Transaction().add(
              createTransferInstruction(
                sourceWalletCoinAccount,
                destinationWalletCoinAccount,
                publicKey,
                traitDict[traitToPurchase.assetID].price * finalDecimal,
                [],
                TOKEN_PROGRAM_ID
              )
            );

            // our fee for purchases
            transaction.add(
              web3.SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: feeWallet,
                lamports: web3.LAMPORTS_PER_SOL * 0.03,
              })
            );

            transaction.add(
              createTransferCheckedInstruction(
                sourceWalletTraitAccount,
                traitPublicKey,
                destinationWalletTraitAccount,
                astralWallet,
                1,
                0
              )
            );

            const latestBlockhash = await connection.getLatestBlockhash();
            transaction.feePayer = publicKey;
            transaction.recentBlockhash = latestBlockhash.blockhash;
            let sendSigned;
            try {
              sendSigned = await signTransaction(transaction);
              sendSigned.partialSign(walletKey);
            }
            catch (error) {
              console.log("error 3")
              setPopup(true);
              setPopupState("transactionError");
              timeoutRef.current = setTimeout(cancelPopup, 5000)
              throw error;
            }

            try {
              const signature = await connection.sendRawTransaction(sendSigned.serialize());
              // console.log(signature)
              await confirmPurchase(traitDict[traitToPurchase.assetID].hash, publicKey, signature, traitDict[traitToPurchase.assetID].id);
              const sent = connection
                .confirmTransaction({
                  blockhash: latestBlockhash.blockhash,
                  lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
                  signature: signature,
                })
                .then(async (sentData) => {
                  setPopup(true)
                  setPopupState("successfulPurchase");
                  timeoutRef.current = setTimeout(cancelPopup, 7000)
                });
            }
            catch (error) {
              console.log("error 4")
              setPopup(true);
              setPopupState("transactionError");
              timeoutRef.current = setTimeout(cancelPopup, 5000)
              throw error;
            }
          }
          catch (error){
            setPopup(true);
            setPopupState("transactionError");
            timeoutRef.current = setTimeout(cancelPopup, 5000)
            throw error
          }
        }

        else {
          setPopup(true);
          setPopupState("ineligible");
          timeoutRef.current = setTimeout(cancelPopup, 5000)
        }
      }
      catch (error){
        setPopup(true);
        setPopupState("transactionError");
        console.log(error)

      }
    },
    [publicKey, sendTransaction, connection, raceTrait, backgroundTrait, topTrait, bottomTrait, feetTrait, legendaryTrait]
  );

  const cancelPopup = () => {
    setPopup(false)
  }

  // log purchase into the DB
  const confirmPurchase = async (hash, publicKey, signature, traitID) => {
    var data = JSON.stringify({
      action: "setTraitPurchase",
      traitID: traitID,
      purchasingWallet: publicKey,
      transactionID: signature,
      hash: hash,
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/astralmint",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    let returnResponse = axios(config)
      .then(function (response) {
        // console.log(response)
        return true;
      })
      .catch(function (error) {
        return false;
      });

    return returnResponse;
  };

  // post purchase we reload the traits so that the quantities are updated
  const resetTraitShop = () => {
    var data = JSON.stringify({
      projectID: props.projectID,
      action: "getBodyShopTraits",
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/getlivetraits",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        var traits = [];
        response.data.data.forEach((trait) => {
          if (trait.live){
            const traitInfo = {
              id: trait.id,
              name: trait.traitName,
              type: trait.type,
              creator: trait.createdBy,
              category: trait.category,
              supply: trait.supply,
              amountPurchased: trait.amountPurchased,
              soldOut: trait.soldOut,
              price: trait.price,
              currencyHash: trait.currencyHash,
              display: trait.image,
              description: trait.description,
              live: trait.live,
              identifyingHash: trait.identifyingHash,
              projectID: trait.projectID,
              SFTHash: trait.SFTHash,
            };
            traits.push(traitInfo);
            if (selectedTrait.id === trait.id) {
              setSelectedTrait(traitInfo);
            }
          }
        });
        setAllTraits(traits)
        setTraitCategories(response.data.typeDict);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  // remove the popup from the screen and reset the trait shop
  const resetPopup = () => {
    setPopup(false);
    setPopupState("");
    resetTraitShop();
  };

  // determines the type of popup that appears - toggled between pending, successful, and failed transaction
  const renderPopup = () => {
    if (popupState === "ineligible") {
      return (
        <div class="fixed inset-0 flex flex-rows items-start justify-center z-50">
          <img className="flex relative w-96" src={PopupIneligible}/>
        </div>
      );
    }
    else if (popupState === "internalError") {
      return (
        <div class="fixed inset-0 flex flex-rows items-start justify-center z-50">
          <img className="flex relative w-96" src={PopupInternalError}/>
        </div>
      );
    }
    else if (popupState === "noFunds") {
      return (
        <div class="fixed inset-0 flex flex-rows items-start justify-center z-50">
          <img className="flex relative w-96" src={PopupNoFunds}/>
        </div>
      );
    }
    else if (popupState === "transactionError") {
      return (
        <div class="fixed inset-0 flex flex-rows items-start justify-center z-50">
          <img className="flex relative w-96" src={PopupTransactionError}/>
        </div>
      );
    }
    else if (popupState === "walletPrompt") {
      return (
        <div class="fixed inset-0 flex flex-rows items-start justify-center z-50">
          <div className="relative flex flex-rows">
            <img className="absolute bottom-0.25 left-0 z-10 w-11" src={Loader}/>
            <img className="flex relative w-96" src={PopupWalletPrompt}/>
          </div>
        </div>
      );
    }
    else if (popupState === "successfulPurchase") {
      return (
        <div class="fixed inset-0 flex flex-rows items-start justify-center z-50">
          <img className="flex relative w-96" src={PopupSuccessfulPurchase}/>
        </div>
      );
    }
  };

  const chooseNFT = (nftHash) => {
    // console.log(userProjectNFTs[nftHash], traitDict)
    setSelectedNFT(nftHash)
    setSelectedTrait(0)
    setImageArray()
    setUpgradeError(false)
    setShowNFTPopup(false)
    setChoseTrait(false)

    let tempImageArray = []
    const v1RaceCheck = [
            "Akabo",
            "Azuli Citizen",
            "Belua ",
            "Boraqchk",
            "Broos",
            "Contemplar Mechanic",
            "Gulatroxian",
            "Peaky Inruina",
            "Nihivor Drone",
            "Oevrumetus",
            "Regina Hunter",
            "Sa'Nasi Merc",
            "Spiran",
            "Calm Xenu",
        ]
    // setBackgroundTrait({trait_type: "Background", value: userProjectNFTs[nftHash].attributeDict.Background, assetID: userProjectNFTs[nftHash].attributeIDDict.Background, addedReward: userProjectNFTs[nftHash].eligibilityDict.Background.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Background.requiredClass})
    setBackgroundTrait({trait_type: "Background", value: "None", assetID: -1, addedReward: 0, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Background.requiredClass})
    tempImageArray.push({trait_type: "Background", value: "None", assetID: -1, addedReward: 0, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Background.requiredClass})

    setRaceTrait({trait_type: "Race", value: userProjectNFTs[nftHash].attributeDict.Race, assetID: userProjectNFTs[nftHash].attributeIDDict.Race, addedReward: userProjectNFTs[nftHash].eligibilityDict.Race.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Race.requiredClass, price: traitDict[userProjectNFTs[nftHash].attributeIDDict.Race].price})
    tempImageArray.push({trait_type: "Race", value: userProjectNFTs[nftHash].attributeDict.Race, assetID: userProjectNFTs[nftHash].attributeIDDict.Race, addedReward: userProjectNFTs[nftHash].eligibilityDict.Race.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Race.requiredClass})
    v1RaceCheck.includes(userProjectNFTs[nftHash].attributeDict.Race) ?
      setCurrentRaceVersion("V1")
      :
      setCurrentRaceVersion("V2")

    if (Object.keys(userProjectNFTs[nftHash].eligibilityDict).includes("Feet")){
      setFeetTrait({trait_type: "Feet", value: userProjectNFTs[nftHash].attributeDict.Feet, assetID: userProjectNFTs[nftHash].attributeIDDict.Feet, addedReward: userProjectNFTs[nftHash].eligibilityDict.Feet.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Feet.requiredClass, price: traitDict[userProjectNFTs[nftHash].attributeIDDict.Race].price})
      tempImageArray.push({trait_type: "Feet", value: userProjectNFTs[nftHash].attributeDict.Feet, assetID: userProjectNFTs[nftHash].attributeIDDict.Feet, addedReward: userProjectNFTs[nftHash].eligibilityDict.Feet.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Feet.requiredClass})
    }
    else{
      setFeetTrait({trait_type: "Feet", value: userProjectNFTs[nftHash].attributeDict.Feet, assetID: userProjectNFTs[nftHash].attributeIDDict.Feet, addedReward: 0, requiredClass: 'Rookie', price: 0})
      tempImageArray.push({trait_type: "Feet", value: userProjectNFTs[nftHash].attributeDict.Feet, assetID: userProjectNFTs[nftHash].attributeIDDict.Feet})
    }

    if (Object.keys(userProjectNFTs[nftHash].eligibilityDict).includes("Bottom")){
      setBottomTrait({trait_type: "Bottom", value: userProjectNFTs[nftHash].attributeDict.Bottom, assetID: userProjectNFTs[nftHash].attributeIDDict.Bottom, addedReward: userProjectNFTs[nftHash].eligibilityDict.Bottom.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Bottom.requiredClass, price: traitDict[userProjectNFTs[nftHash].attributeIDDict.Race].price})
      tempImageArray.push({trait_type: "Bottom", value: userProjectNFTs[nftHash].attributeDict.Bottom, assetID: userProjectNFTs[nftHash].attributeIDDict.Bottom, addedReward: userProjectNFTs[nftHash].eligibilityDict.Bottom.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Bottom.requiredClass})
    }
    else{
      setBottomTrait({trait_type: "Bottom", value: userProjectNFTs[nftHash].attributeDict.Bottom, assetID: userProjectNFTs[nftHash].attributeIDDict.Bottom, addedReward: 0, requiredClass: 'Rookie', price: 0})
      tempImageArray.push({trait_type: "Bottom", value: userProjectNFTs[nftHash].attributeDict.Bottom, assetID: userProjectNFTs[nftHash].attributeIDDict.Bottom})
    }

    if (Object.keys(userProjectNFTs[nftHash].eligibilityDict).includes("Top")){
      setTopTrait({trait_type: "Top", value: userProjectNFTs[nftHash].attributeDict.Top, assetID: userProjectNFTs[nftHash].attributeIDDict.Top, addedReward: userProjectNFTs[nftHash].eligibilityDict.Top.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Top.requiredClass})
      tempImageArray.push({trait_type: "Top", value: userProjectNFTs[nftHash].attributeDict.Top, assetID: userProjectNFTs[nftHash].attributeIDDict.Top, addedReward: userProjectNFTs[nftHash].eligibilityDict.Top.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Top.requiredClass})
    }
    else{
      setTopTrait({trait_type: "Top", value: userProjectNFTs[nftHash].attributeDict.Top, assetID: userProjectNFTs[nftHash].attributeIDDict.Top, addedReward: 0, requiredClass: 'Rookie', price: 0})
      tempImageArray.push({trait_type: "Top", value: userProjectNFTs[nftHash].attributeDict.Top, assetID: userProjectNFTs[nftHash].attributeIDDict.Top})
    }

    if (Object.keys(userProjectNFTs[nftHash].eligibilityDict).includes("Legendary")){
      setLegendaryTrait({trait_type: "Legendary", value: userProjectNFTs[nftHash].attributeDict.Legendary, assetID: userProjectNFTs[nftHash].attributeIDDict.Legendary, addedReward: userProjectNFTs[nftHash].eligibilityDict.Legendary.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Legendary.requiredClass, price: traitDict[userProjectNFTs[nftHash].attributeIDDict.Race].price})
      tempImageArray.push({trait_type: "Legendary", value: userProjectNFTs[nftHash].attributeDict.Legendary, assetID: userProjectNFTs[nftHash].attributeIDDict.Legendary, addedReward: userProjectNFTs[nftHash].eligibilityDict.Legendary.addedReward, requiredClass: userProjectNFTs[nftHash].eligibilityDict.Legendary.requiredClass})
    }
    else{
      setLegendaryTrait({trait_type: "Legendary", value: userProjectNFTs[nftHash].attributeDict.Legendary, assetID: userProjectNFTs[nftHash].attributeIDDict.Legendary, addedReward: 0, requiredClass: 'Rookie', price: 0})
      tempImageArray.push({trait_type: "Legendary", value: userProjectNFTs[nftHash].attributeDict.Legendary, assetID: userProjectNFTs[nftHash].attributeIDDict.Legendary})
    }

    generateImage(true, tempImageArray)

    let eligibleClassesTemp = []
    if (userProjectNFTs[nftHash].attributeDict.Class === "Rookie"){
      eligibleClassesTemp.push("Rookie")
    }
    else if (userProjectNFTs[nftHash].attributeDict.Class === "Soldier"){
      eligibleClassesTemp.push("Rookie")
      eligibleClassesTemp.push("Soldier")
    }
    else if (userProjectNFTs[nftHash].attributeDict.Class === "Elite"){
      eligibleClassesTemp.push("Rookie")
      eligibleClassesTemp.push("Soldier")
      eligibleClassesTemp.push("Elite")
    }
    else if (userProjectNFTs[nftHash].attributeDict.Class === "Destructor"){
      eligibleClassesTemp.push("Rookie")
      eligibleClassesTemp.push("Soldier")
      eligibleClassesTemp.push("Elite")
      eligibleClassesTemp.push("Destructor")
    }
    renderSatusBarType(userProjectNFTs[nftHash].attributeDict.Xyon)
    setEligibleClasses(eligibleClassesTemp)
    setSelectedNFTClass(userProjectNFTs[nftHash].attributeDict.Class)
    setSelectedXyon(userProjectNFTs[nftHash].attributeDict.Xyon)
  }

  const renderNFTs = () => {
    // console.log(userProjectNFTs)
    return(
      <div style={{ maxHeight: "calc(100vh - 200px)",  minHeight: "calc(100vh - 200px)"}} className="hide-scrollbar w-[80%] h-full  text-center overflow-y-scroll">
          { Object.keys(userProjectNFTs)?.length ?
              <div className="grid grid-cols-4 2xl:gap-12 gap-16 pb-5">
                  { Object.keys(userProjectNFTs).map(nftHash => {
                      return (
                          <div onClick={() => chooseNFT(nftHash)} class="w-full pb-[100%] relative   cursor-pointer  ">
                              <img
                                src={`${userProjectNFTs[nftHash].imageLink}?${new Date().getTime()}`}
                                className="absolute w-full h-full object-contain rounded-3xl top-0 left-0 transition-all  hover:shadow-lg hover:scale-95"
                              />
                          </div>
                      );
                  })}
               </div>:
                (
                    <div className="w-full h-full justify-center items-center text-center xl:w-1/2 mx-auto text-2xl font-title-regular text-white">
                        There is no NFT in your wallet from this particular collection. Please connect a new wallet and
                        try again or switch the collection.
                    </div>
                )
          }
      </div>
    )
  }

  const renderImage = () => {
    if (selectedNFT) {
      if (upgradeError) {
        return(
          <div className="flex justify-center mx-auto mb-5  relative rounded-md   w-full">
              <div
                style={{ borderColor: theme?.color1 }}
                className="w-full pb-[calc(100%-4px)] relative rounded-md border-2 border-primary-red overflow-hidden"
              >
                  <img className="absolute  top-0 left-0  w-full h-full object-contain" src={NotAllowed} />
              </div>
          </div>
        )
      }

      // If the combination was allowed and the NFT is set to upload
      // className="absolute w-full h-full min-w-[800px] min-h-[800px] aspect-square h-auto bottom-0 left-1/2 -translate-x-1/2  object-contain"
      else if (imageArray) {
        return (

          <div>
            { imageArray.map(link => (
                <img
                  className="w-full h-full object-cover absolute top-0 left-0"
                  src={link}
                  alt='Selected NFT'
                />
            ))}
          </div>
        );
      }

      // Selected image with no selected trait - only edit is if the NFT is upgrading it will show the latest image
      else {
        return (
          <div>
          </div>
        );
      }
    }
  };

  const changeTrait = (category, trait) => {
    setChoseTrait(true)
    if (category === "Race"){
      setRaceTrait({trait_type: "Race", value: trait.traitName, assetID: trait.assetID, addedReward: trait.addedReward, requiredClass: trait.requiredClass, price: traitDict[trait.assetID].price})
      setRaceIndex(assetDict[category][currentRaceVersion].indexOf(trait))
    }
    else if (category === "Background"){
      setBackgroundTrait({trait_type: "Background", value: trait.traitName, assetID: trait.assetID, addedReward: trait.addedReward, requiredClass: trait.requiredClass})
    }
    else if (category === "Top"){
      setTopTrait({trait_type: "Top", value: trait.traitName, assetID: trait.assetID, addedReward: trait.addedReward, requiredClass: trait.requiredClass, price: traitDict[trait.assetID].price})
      setTopIndex(assetDict[category].indexOf(trait))
    }
    else if (category === "Bottom"){
      setBottomTrait({trait_type: "Bottom", value: trait.traitName, assetID: trait.assetID, addedReward: trait.addedReward, requiredClass: trait.requiredClass, price: traitDict[trait.assetID].price})
      setBottomIndex(assetDict[category].indexOf(trait))
    }
    else if (category === "Feet"){
      setFeetTrait({trait_type: "Feet", value: trait.traitName, assetID: trait.assetID, addedReward: trait.addedReward, requiredClass: trait.requiredClass, price: traitDict[trait.assetID].price})
      setFeetIndex(assetDict[category].indexOf(trait))
    }
    else if (category === "Legendary"){
      setLegendaryTrait({trait_type: "Legendary", value: trait.traitName, assetID: trait.assetID, addedReward: trait.addedReward, requiredClass: trait.requiredClass, price: traitDict[trait.assetID].price})
      setLegendaryIndex(assetDict[category].indexOf(trait))
    }
  }

  const renderClass = () => {
    if (selectedNFTClass === "Rookie"){
      return (
        <img class="w-[28%] 2xl:w-[32%]" src={RookieBadge}></img>
      )
    }
    else if(selectedNFTClass === "Soldier"){
      return (
        <img class="w-[28%] 2xl:w-[32%]" src={SoldierBadge}></img>
      )
    }
    else if(selectedNFTClass === "Elite"){
      return (
        <img class="w-[28%] 2xl:w-[32%]" src={EliteBadge}></img>
      )
    }
    else if(selectedNFTClass === "Destructor"){
      return (
        <img class="w-[28%] 2xl:w-[32%]" src={DestructorBadge}></img>
      )
    }
  }

  const renderSatusBarType = (xyon) => {
    // console.log(xyon)
    if (xyon === 0){
      setPercentageStatus(0)
    }
    else if (xyon <= 500){
      let calculatePercentage = (xyon / 500) * 100;
      setPercentageStatus(calculatePercentage)
    }
    else if (xyon <= 5000){
      // console.log("got here to 5000")
      let calculatePercentage = (xyon / 5000) * 100;
      setPercentageStatus(calculatePercentage)
    }
    else if (xyon <= 10000){
      let calculatePercentage = (xyon / 10000) * 100;
      setPercentageStatus(calculatePercentage)
    }
    else {
      setPercentageStatus(100)
    }

  }

  const renderMoreInfo = () => {
    // <img src={PopupInfo}/>
    return (
      <>
      <img class="object-cover w-full h-full" src={PopupInfo}/>
      <div className = "absolute flex-col top-2 left-3 w-[90%] h-full">
        <h1 className = "border-b-2 border-white text-astral-class font-title-bold font-medium  uppercase text-md 2xl:text-xl 4xl:text-4xl"> RACE</h1>
        <div class="grid grid-cols-4 gap-4 max-h-[13%] min-h-[13%] leading-tight pt-3 mb-2">
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">GEAR:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{raceTrait.value}</span>
          </p>
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">PRICE:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{raceTrait.price} $GLXY</span>
          </p>
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">CLASS:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{raceTrait.requiredClass}</span>
          </p>
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">XYON:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{raceTrait.addedReward}</span>
          </p>
        </div>
        <h1 className = "border-b-2 border-white text-astral-class font-title-bold font-medium uppercase text-md 2xl:text-xl 4xl:text-4xl"> TOP </h1>
        <div class="grid grid-cols-4 gap-4 max-h-[13%] min-h-[13%] leading-tight pt-3 mb-2">
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">GEAR:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{topTrait.value}</span>
          </p>
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">PRICE:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{topTrait.price} $GLXY</span>
          </p>
          <p className = "text-white font-title-bold font-medium uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">CLASS:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{topTrait.requiredClass}</span>
          </p>
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">XYON:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{topTrait.addedReward}</span>
          </p>
        </div>
        <h1 className = "border-b-2 border-white text-astral-class font-title-bold font-medium uppercase text-md 2xl:text-xl 4xl:text-4xl"> BOTTOM </h1>
        <div class="grid grid-cols-4 gap-4 max-h-[13%] min-h-[13%] leading-tight pt-3 mb-2">
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header ">GEAR:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{bottomTrait.value}</span>
          </p>
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">PRICE:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{bottomTrait.price} $GLXY</span>
          </p>
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">CLASS:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{bottomTrait.requiredClass}</span>
          </p>
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">XYON:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{bottomTrait.addedReward}</span>
          </p>
        </div>
        <h1 className = "border-b-2 border-white text-astral-class font-title-bold font-medium uppercase text-md 2xl:text-xl 4xl:text-4xl"> FEET </h1>
        <div class="grid grid-cols-4 gap-4 max-h-[13%] min-h-[13%] leading-tight pt-3 mb-2">
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">GEAR:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{feetTrait.value}</span>
          </p>
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">PRICE:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{feetTrait.price} $GLXY</span>
          </p>
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">CLASS:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{feetTrait.requiredClass}</span>
          </p>
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">XYON:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{feetTrait.addedReward}</span>
          </p>
        </div>
        <h1 className = "border-b-2 border-white text-astral-class font-title-bold font-medium uppercase text-md 2xl:text-xl 4xl:text-4xl"> LEGENDARY </h1>
        <div class="grid grid-cols-4 gap-4 max-h-[13%] min-h-[13%] leading-tight pt-3 mb-2">
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">GEAR:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{legendaryTrait.value}</span>
          </p>
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">PRICE:</span>
            <br></br>
            <span className="font-title-bold font-medium  text-[50%] 2xl:text-[70%]">{legendaryTrait.price} $GLXY</span>
          </p>
          <p className = "text-white font-title-bold font-medium uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">CLASS:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{legendaryTrait.requiredClass}</span>
          </p>
          <p className = "text-white font-title-bold font-medium  uppercase text-[90%] 2xl:text-[100%] 3xl:text-[120%] 4xl:text-[180%]">
            <span className="text-more-info-header">XYON:</span>
            <br></br>
            <span className="text-[50%] 2xl:text-[70%]">{legendaryTrait.addedReward}</span>
          </p>
        </div>
      </div>
      </>

    )
  }

  const changeRaceVersion = (raceVersion) => {
    // console.log(raceVersion)
    let newRaceName
    if (raceVersion === "V1"){
      newRaceName = raceTrait.value.replace("2", "1")
    }
    else{
      newRaceName = raceTrait.value.replace("1", "2")
    }
    // console.log(assetDict.Race)
    let matchRace = {
        "Akabo": "Akabo Skeez",
        "Akabo Skeez": "Akabo",
        "Azuli Citizen": "Azuli Cardinal",
        "Azuli Cardinal":"Azuli Citizen",
        "Belua ": "Belua Rogue",
        "Belua Rogue": "Belua ",
        "Boraqchk" : "Boraqchk Explorer",
        "Boraqchk Explorer": "Boraqchk",
        "Broos": "Broos Rage",
        "Broos Rage": "Broos",
        "Contemplar Mechanic": "Contemplar Foreman",
        "Contemplar Foreman": "Contemplar Mechanic",
        "Gulatroxian": "Gulatroxian Mutant",
        "Gulatroxian Mutant": "Gulatroxian",
        "Peaky Inruina": "Inruina Grunt",
        "Inruina Grunt": "Peaky Inruina",
        "Nihivor Drone": "Nihivor Noble",
        "Nihivor Noble": "Nihivor Drone",
        "Oevrumetus": "Tribal Oevrumetus ",
        "Tribal Oevrumetus ": "Oevrumetus",
        "Regina Hunter": "Regina Commander",
        "Regina Commander":"Regina Hunter",
        "Sa'Nasi Merc": "Sa'Nasi Guard",
        "Sa'Nasi Guard": "Sa'Nasi Merc",
        "Spiran": "Spiran Mate ",
        "Spiran Mate ": "Spiran",
        "Calm Xenu": "Battle Ready Xenu",
        "Battle Ready Xenu": "Calm Xenu"
    }
    let traitTemp = assetDict.Race[raceVersion].find(trait => matchRace[trait.traitName] === newRaceName)
    // console.log(traitTemp)
    setRaceTrait({trait_type: "Race", value: traitTemp.traitName, assetID: traitTemp.assetID, addedReward: traitTemp.addedReward, requiredClass: traitTemp.requiredClass})
    setCurrentRaceVersion(raceVersion)
  }

  return (
    <>
        { publicKey ?
              selectedNFT ?
                  <div className="grid grid-cols-3 min-w-[1500px] 2xl:min-h-[900px] 4xl:min-w-[2560px] h-screen -mt-20">

                    <div className="relative z-10 mt-8">
                      <div className="flex relative left-12 gap-4 mb-5">
                        {renderClass()}
                        <div className = "flex flex-col justify-center items-center px-6">
                          <div className = "flex flex-col justify-center text-white font-title-bold uppercase bg-black bg-opacity-60 rounded-xl px-6">
                            <div className = "flex gap-6 justify-center text-[200%] 2xl:text-[250%] 4xl:text-[350%] font-gseven items-center">
                              {selectedNFTXyon}
                              <img className="object-contain h-[40%]" src={XyonsImage}/>
                            </div>
                            {
                              percentageStatus === 0 ?
                              <img className="object-contain w-[90%]" src={StatusBarZero}/>
                              :
                              <>
                              {
                                percentageStatus <= 25 ?
                                <img className="object-contain w-[90%]" src={StatusBarTwentyFive}/>
                                :
                                <>
                                {
                                  percentageStatus <= 50 ?
                                  <img className="object-contain w-[90%]" src={StatusBarFifty}/>
                                  :
                                  <>
                                  {
                                    percentageStatus <= 75 ?
                                    <img className="object-contain w-[90%]" src={StatusBarSeventyFive}/>
                                    :
                                    <>
                                    {
                                      percentageStatus === 100 ?
                                      <img className="object-contain w-[90%]" src={StatusBar}/>
                                      :
                                      <img className="object-contain w-[90%]" src={StatusBarSeventyFive}/>
                                    }
                                    </>
                                  }
                                  </>
                                }
                                </>
                              }
                              </>
                            }
                            <div className="font-elasis text-astral-class text-[180%] 2xl:text-[220%] 4xl:text-[330%]">{selectedNFTClass}</div>
                          </div>
                        </div>
                      </div>
                      <div className="relative h-[65%] w-[90%] left-16 text-white">
                        {renderMoreInfo()}
                        <div class="flex justify-end w-full mt-2 gap-2">
                          <img src={ChooseNFT} onClick={() => setSelectedNFT(false)} class="w-[40%] hover:cursor-pointer chooseNFT"/>
                          <img src={ResetTraits} onClick={() => chooseNFT(selectedNFT)} class="w-[40%] hover:cursor-pointer resetTraits"/>
                        </div>
                      </div>
                    </div>
                    <div class="relative flex-cols justify-center items-end">
                        {
                          popup ?
                          <>
                          {renderPopup()}
                          </>
                          :
                          ""
                        }
                        { renderImage() }
                    </div>
                    <div className="relative p-8 z-10 mt-20 4xl:mt-48">
                        <div className={"flex flex-col items-center text-white font-title-bold uppercase mb-2 bg-gradient-to-t from-light-blue-transparent to-light-blue-ultra-transparent rounded-lg hover:from-blue-transparent hover:to-blue-ultra-transparent"}>
                          { Object.keys(assetDict).length && Object.keys(assetDict)
                          .filter(category => category === 'Race')
                          .map(category => {
                            return (
                              <>
                                <div className="flex flex-cols mb-2 justify-center items-center gap-2">
                                    {
                                      raceIndex > 0 ?
                                      <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] leftArrow z-20" src={LeftArrowInactive} onClick={() => changeTrait(category, assetDict[category][currentRaceVersion][raceIndex-1])}/>
                                      :
                                      <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] leftArrow z-20" src={LeftArrowInactive} onClick={() => changeTrait(category, assetDict[category][currentRaceVersion][assetDict[category][currentRaceVersion].length-1])}/>
                                    }
                                    <div className="flex flex-cols justify-center items-center gap-4">
                                      {
                                        currentRaceVersion === "V1" ?
                                        <img className="w-[28%]" src={V1ButtonActive}></img>
                                        :
                                        <img className="hover:cursor-pointer w-[28%] v1Button" src={V1ButtonInactive} onClick={() => changeRaceVersion("V1")} ></img>
                                      }
                                      <div className="font-title-bold font-medium text-[120%] 2xl:text-[200%] 4xl:text-[250%] text-center">
                                          {category}
                                      </div>
                                      {
                                        currentRaceVersion === "V2" ?
                                        <img className="w-[28%]" src={V2ButtonActive}></img>
                                        :
                                        <img className="hover:cursor-pointer w-[28%] v2Button" src={V2ButtonInactive} onClick={() => changeRaceVersion("V2")} ></img>
                                      }
                                    </div>
                                    {
                                      raceIndex >= 0 && raceIndex < assetDict[category][currentRaceVersion].length-1 ?
                                      <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] rightArrow z-20" src={RightArrowInactive} onClick={() => changeTrait(category, assetDict[category][currentRaceVersion][raceIndex+1])}/>
                                      :
                                      <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] rightArrow z-20" src={RightArrowInactive} onClick={() => changeTrait(category, assetDict[category][currentRaceVersion][0])}/>
                                    }
                                </div>
                                <div className="flex flex-wrap items-center justify-center">
                                  {
                                    assetDict[category][currentRaceVersion].map(trait => (
                                      <>
                                      {
                                        trait.assetID === raceTrait.assetID ?
                                        <div onClick={() => changeTrait(category, trait)} className="w-4 2xl:w-6 4xl:w-8 h-4 2xl:h-6 4xl:h-8 bg-astral-box-picked border-2 border-astral-box-border-picked rounded-sm mr-2 mb-2">
                                        </div>
                                        :
                                        <div onClick={() => changeTrait(category, trait)} className="hover:cursor-pointer hover:bg-astral-box-picked w-4 2xl:w-6 4xl:w-8 h-4 2xl:h-6 4xl:h-8 bg-astral-box border border-astral-box-border rounded-sm mr-2 mb-2">
                                        </div>
                                      }
                                      </>
                                    ))
                                  }
                                </div>
                                <img src={BuyNowInactive} onClick={() => purchaseTrait(category)} className="hover:cursor-pointer buyNow w-[30%] mb-2 z-20"/>
                              </>
                            )
                        })}
                        </div>
                        <div className={"flex flex-col items-center gradient-border text-white font-title-bold uppercase mb-2 bg-gradient-to-t from-light-blue-transparent to-light-blue-ultra-transparent rounded-lg hover:from-blue-transparent hover:to-blue-ultra-transparent"}>
                          { Object.keys(assetDict).length && Object.keys(assetDict)
                          .filter(category => category !== 'Race' && category !== 'Legendary' && category !== 'Background')
                          .map(category => {
                            return (
                              <>
                              <div className="flex gap-2 justify-center items-center 2xl:mb-2">
                                  {
                                    category === 'Top' ?
                                    <>
                                    {
                                      topIndex > 0 ?
                                      <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] leftArrow z-20" src={LeftArrowInactive} onClick={() => changeTrait(category, assetDict[category][topIndex-1])}/>
                                      :
                                      <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] leftArrow z-20" src={LeftArrowInactive} onClick={() => changeTrait(category, assetDict[category][assetDict[category].length-1])}/>
                                    }
                                    </>
                                    :
                                    <>
                                    {
                                      category === 'Bottom' ?
                                      <>
                                      {
                                        bottomIndex > 0 ?
                                        <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] leftArrow z-20" src={LeftArrowInactive} onClick={() => changeTrait(category, assetDict[category][bottomIndex-1])}/>
                                        :
                                        <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] leftArrow z-20" src={LeftArrowInactive} onClick={() => changeTrait(category, assetDict[category][assetDict[category].length-1])}/>
                                      }
                                      </>
                                      :
                                      <>
                                      {
                                        category === 'Feet' ?
                                        <>
                                        {
                                          feetIndex > 0 ?
                                          <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] leftArrow z-20" src={LeftArrowInactive} onClick={() => changeTrait(category, assetDict[category][feetIndex-1])}/>
                                          :
                                          <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] leftArrow z-20" src={LeftArrowInactive} onClick={() => changeTrait(category, assetDict[category][assetDict[category].length-1])}/>
                                        }
                                        </>
                                        :
                                        ""
                                      }
                                      </>
                                    }
                                    </>
                                  }
                                  <div className="font-title-bold font-medium text-[120%] 2xl:text-[200%] 4xl:text-[250%] text-center justify-center min-w-[280px] 2xl:min-w-[320px] 4xl:min-w-[350px]">
                                      {category}
                                  </div>
                                  {
                                    category === 'Top' ?
                                    <>
                                    {
                                      topIndex >= 0 && topIndex < assetDict[category].length-1 ?
                                      <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] rightArrow z-20" src={RightArrowInactive} onClick={() => changeTrait(category, assetDict[category][topIndex+1])}/>
                                      :
                                      <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] rightArrow z-20" src={RightArrowInactive} onClick={() => changeTrait(category, assetDict[category][0])}/>
                                    }
                                    </>
                                    :
                                    <>
                                    {
                                      category === 'Bottom' ?
                                      <>
                                      {
                                        bottomIndex >= 0 && bottomIndex < assetDict[category].length-1 ?
                                        <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] rightArrow z-20" src={RightArrowInactive} onClick={() => changeTrait(category, assetDict[category][bottomIndex+1])}/>
                                        :
                                        <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] rightArrow z-20" src={RightArrowInactive} onClick={() => changeTrait(category, assetDict[category][0])}/>
                                      }
                                      </>
                                      :
                                      <>
                                      {
                                        category === 'Feet' ?
                                        <>
                                        {
                                          feetIndex >= 0 && feetIndex < assetDict[category].length-1 ?
                                          <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] rightArrow z-20" src={RightArrowInactive} onClick={() => changeTrait(category, assetDict[category][feetIndex+1])}/>
                                          :
                                          <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] rightArrow z-20" src={RightArrowInactive} onClick={() => changeTrait(category, assetDict[category][0])}/>
                                        }
                                        </>
                                        :
                                        ""
                                      }
                                      </>
                                    }
                                    </>
                                  }
                              </div>
                              <div className="flex flex-wrap items-center justify-center">
                                {
                                assetDict[category].map(trait => {
                                  var traitPicked = ''
                                  if (category === 'Top'){
                                    traitPicked = topTrait.assetID
                                  }
                                  else if (category === 'Bottom'){
                                    traitPicked = bottomTrait.assetID
                                  }
                                  else if (category === 'Feet'){
                                    traitPicked = feetTrait.assetID
                                  }
                                  return (
                                    <>
                                    {
                                      traitPicked === trait.assetID ?
                                      <div onClick={() => changeTrait(category, trait)} className="w-4 2xl:w-6 4xl:w-8 h-4 2xl:h-6 4xl:h-8 bg-astral-box-picked border-2 border-astral-box-border-picked rounded-sm mr-2 mb-2 z-20">
                                      </div>
                                      :
                                      <div onClick={() => changeTrait(category, trait)} className="hover:cursor-pointer hover:bg-astral-box-picked w-4 2xl:w-6 4xl:w-8 h-4 2xl:h-6 4xl:h-8 bg-astral-box border border-astral-box-border rounded-sm mr-2 mb-2 z-20">
                                      </div>
                                    }
                                    </>
                                  )
                                }
                                )
                              }
                              </div>
                              <img src={BuyNowInactive} onClick={() => purchaseTrait(category)} className="hover:cursor-pointer buyNow w-[30%] mb-4 z-20"/>
                              </>
                            )
                        })}
                        </div>
                        <div className={"flex flex-col items-center gradient-yellow border-outline-other text-white font-title-bold uppercase bg-gradient-to-b from-light-yellow-transparent to-light-yellow-ultra-transparent hover:from-yellow-transparent hover:to-yellow-ultra-transparent rounded-lg"}>
                        { Object.keys(assetDict).length && Object.keys(assetDict)
                          .filter(category => category === 'Legendary')
                          .map(category => {
                            return (
                              <>
                                <div className="flex mb-2 gap-2 items-center justify-center">
                                  {
                                    legendaryIndex > 0 ?
                                    <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] legLeftArrow z-20" src={LegLeftArrowInactive} onClick={() => changeTrait(category, assetDict[category][legendaryIndex-1])}/>
                                    :
                                    <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] legLeftArrow z-20" src={LegLeftArrowInactive} onClick={() => changeTrait(category, assetDict[category][assetDict[category].length-1])}/>
                                  }
                                  <div className="font-title-bold font-medium text-[120%] 2xl:text-[200%] 4xl:text-[250%] text-center justify-center min-w-[280px] 2xl:min-w-[320px] 4xl:min-w-[350px]">
                                    {category}
                                  </div>
                                  {
                                    legendaryIndex >= 0 && legendaryIndex < assetDict[category].length-1 ?
                                    <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] legRightArrow z-20" src={LegRightArrowInactive} onClick={() => changeTrait(category, assetDict[category][legendaryIndex+1])}/>
                                    :
                                    <img className="hover:cursor-pointer w-[10%] h-[10%] 4xl:w-[16%] 4xl:h-[16%] legRightArrow z-20" src={LegRightArrowInactive} onClick={() => changeTrait(category, assetDict[category][0])}/>
                                  }
                                </div>
                                <div className="flex flex-wrap items-center justify-center">
                                  {
                                  assetDict[category].map(trait => (
                                    <>
                                    {
                                      legendaryTrait.assetID === trait.assetID ?
                                      <div onClick={() => changeTrait(category, trait)} className="w-4 2xl:w-6 4xl:w-8 h-4 2xl:h-6 4xl:h-8 bg-leg-astral-box-picked border-2 border-light-yellow-border mr-2 mb-2 rounded-sm z-20">
                                      </div>
                                      :
                                      <div onClick={() => changeTrait(category, trait)} className="hover:cursor-pointer hover:bg-leg-astral-box-picked w-4 2xl:w-6 4xl:w-8 h-4 2xl:h-6 4xl:h-8 bg-leg-astral-box border border-leg-astral-box-border mr-2 mb-2 rounded-sm z-20">
                                      </div>
                                    }
                                    </>

                                  ))
                                }
                                </div>
                                <img src={LegBuyNowInactive} onClick={() => purchaseTrait(category)} className="hover:cursor-pointer legBuyNow w-[30%] z-20"/>
                              </>
                            )
                        })}
                        </div>
                        <div class="flex justify-between gap-10 mt-8 2xl:mt-16">
                          <img src={MintBtnOff} className=" w-[22%] h-[10%] hover:cursor-pointer mintButton" onClick={() => props.setPage('astrals')}/>
                          <img
                            className="hover:cursor-pointer armoryButton w-[55%] h-[20%]"
                            src={ArmoryBtn}
                            alt="Logo"
                            onClick={() => props.setPage("customAstral")}
                          />
                        </div>
                    </div>
                  </div>
                  :
                  <div className="flex flex-col w-full h-screen 4xl:min-w-[2560px] -mt-20 overflow-hidden gap-5 items-center">
                      <p className="text-[2rem] leading-none text-center text-white uppercase max-w-2xl font-title-bold mt-8">SELECT AN NFT TO GET STARTED</p>
                        {renderNFTs()}
                      <div className="flex justify-end items-center w-full h-full z-20 gap-2">
                        <img src={MintBtnOff} className="hover:cursor-pointer mintButton w-28 mb-2 2xl:w-[10%] 4xl:-mt-20" onClick={() => props.setPage('astrals')}/>
                        <img
                          className="hover:cursor-pointer armoryButton w-64 2xl:w-[20%] 4xl:-mt-20 pr-4"
                          src={ArmoryBtn}
                          alt="Logo"
                          onClick={() => props.setPage("customAstral")}
                        />
                      </div>
                  </div>
              :
              <div className="flex flex-col w-full h-full overflow-hidden gap-5 items-center">
                  <p className="text-[2rem] leading-none text-center text-white uppercase max-w-2xl font-title-bold" style={{ minHeight: "calc(100vh - 200px)" }}>
                      Please Connect Your wallet to get started
                  </p>
              </div>
        }
    </>
  );
};

export default AstralTraits;
