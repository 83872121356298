import React, { useState, useEffect } from "react";
import axios from "axios";

import SideBarNew from "../components/SideBarNew";
import ArmoryBtn from "../img/armoryButtonNonActive.png";
import BazaarBtn from "../img/goToBazaar.png";

import AstralsMint from './AstralsMint'
import AstralTraits from './AstralTraits'
import CustomizeAstral from './CustomizeAstral'
import CustomizeBG from "../img/customizer-bg.png"

import MintBtnOn from '../img/mint-on.png'
import MintBtnOff from '../img/mint-off.png'

import './AstralTrait.css'


const Nav = ({ children, ...props }) => {
  let initalPage = 'astralTraits'
  const [page, setPage] = useState(initalPage);
  if (page === 'astralTraits'){
    props.setPage("astralTraits")
  }
  else if (page === 'customAstral'){
    props.setPage("customAstral")
  }
  else if (page === 'astrals'){
    props.setPage("astrals")
  }


  const renderPage = () => {

    return (
      <div className="pt-4 flex flex-col">
        <div className={`flex justify-end items-start w-full relative ${page === 'astrals' ? "" : "min-w-[1500px] 4xl:min-w-[2560px]"}`}>
          <div className = "mr-4">{children}</div>
        </div>
        <div className="px-24">
          {page.includes("astrals") && (
          <AstralsMint
            projectName={props.projectName}
            projectHashURL={props.projectHashURL}
            projectLink={props.projectLink}
            projectID={props.projectID}
            setPage={setPage}
            projectCreator={props.projectCreator}
            projectLogo={props.projectLogo}
            hasReferral = {props.hasReferral}
            referralWallet = {props.referralWallet}
            referralPercentage={props.referralPercentage}
            setPage={setPage}
          />
        )}
        </div>
        {page.includes("customAstral") && (
          <CustomizeAstral
            projectName={props.projectName}
            projectHashURL={props.projectHashURL}
            projectLink={props.projectLink}
            projectID={props.projectID}
            projectLogo={props.projectLogo}
            setPage={setPage}
            projectTraitCollectionHash = {props.projectTraitCollectionHash}
            swapTraits = {props.swapTraits}
            hasReferral = {props.hasReferral}
            referralWallet = {props.referralWallet}
            referralPercentage={props.referralPercentage}
          />
        )}
        {page.includes("astralTraits") && (
          <AstralTraits
            projectName={props.projectName}
            projectHashURL={props.projectHashURL}
            projectLink={props.projectLink}
            projectID={props.projectID}
            setPage={setPage}
            projectCreator={props.projectCreator}
            projectLogo={props.projectLogo}
            hasReferral = {props.hasReferral}
            referralWallet = {props.referralWallet}
            referralPercentage={props.referralPercentage}
          />
        )}
      </div>
    );
  };

  return <div>{renderPage()}</div>;
};

export default Nav;
